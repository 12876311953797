import {
  TselfAuthenticationState,
  ISelfAuthenticationResultRequest,
  ISelfAuthenticationResultPayload,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TselfAuthenticationState = {
  pageName: null,
  process: {
    data: { receiptId: null, name: null },
  },
  result: {
    data: { certificationId: null },
  },
};

export const selfAuthenticationSlice = createSlice({
  name: 'selfAuthentication',
  initialState,
  reducers: {
    resetSelfAuthentication: state => {
      state.pageName = null;
      state.process = {
        data: { receiptId: null, name: null },
      };
      state.result = {
        data: { certificationId: null },
      };
    },
    resetCertificationId: state => {
      state.result.data.certificationId = null;
    },
    selfAuthenticationRequest: (
      state,
      action: PayloadAction<{
        pageName: string;
        name: string;
        userInfo: {
          phone: string;
        };
        functions: {
          onSuccess: (data: any) => void;
          onCancel?: (data: any) => void;
          onError?: (error: any) => void;
        };
      }>,
    ) => {
      state.pageName = action.payload.pageName;
    },
    selfAuthenticationSuccess: (
      state,
      action: PayloadAction<{ receipt_id: string; name: string }>,
    ) => {
      state.process.data = {
        receiptId: action.payload.receipt_id,
        name: action.payload.name,
      };
    },

    selfAuthenticationResultRequest: (
      state,
      action: PayloadAction<ISelfAuthenticationResultRequest>,
    ) => {
      state.result = {
        data: { certificationId: null },
      };
    },
    selfAuthenticationResultSuccess: (
      state,
      action: PayloadAction<ISelfAuthenticationResultPayload>,
    ) => {
      if (action.payload.certificationId)
        state.result.data.certificationId = action.payload.certificationId;
    },
  },
});

export const {
  resetSelfAuthentication,
  resetCertificationId,
  selfAuthenticationRequest,
  selfAuthenticationSuccess,
  selfAuthenticationResultRequest,
  selfAuthenticationResultSuccess,
} = selfAuthenticationSlice.actions;

export default selfAuthenticationSlice.reducer;
