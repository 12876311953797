import ko from 'date-fns/locale/ko';
import {
  format,
  differenceInCalendarDays,
  intervalToDuration,
  Duration,
} from 'date-fns';

export const changeDateFormat = (
  date: Date | number,
  dateFormat: string = 'yy.MM.dd',
) => {
  if (date) {
    return format(date, dateFormat, {
      locale: ko,
    });
  }
};

export const calculateDiffInDays = (
  endDate: Date | number,
  startDate: Date | number,
) => {
  return differenceInCalendarDays(endDate, startDate);
};

export const calculateBookableLimitDate = (
  bookableMonth: number,
  dailyOpenHour: number,
) => {
  const now = new Date();
  return new Date(
    now.setDate(
      now.getDate() +
        (dailyOpenHour > 0 && now.getHours() < dailyOpenHour ? -1 : 0) +
        30 * bookableMonth,
    ),
  );
};

export const changeRegisterDateFormat = (date: Date | number) => {
  const now = new Date();
  const dateInterval = intervalToDuration({ start: date, end: now });
  const { seconds, minutes, hours, days, months, years } = dateInterval;
  let intervalMark = '';

  switch (true) {
    case years && years > 0:
      intervalMark = `${years}년 전`;
      break;

    case months && months < 12:
      intervalMark = `${months}달 전`;
      break;

    case days && days < 31:
      intervalMark = `${days}일 전`;
      break;

    case hours && hours < 24:
      intervalMark = `${hours}시간 전`;
      break;

    case minutes && minutes < 60:
      intervalMark = `${minutes}분 전`;
      break;

    case (seconds as number) >= 0 || (seconds as number) < 60:
      intervalMark = '방금 전';
      break;

    default:
      break;
  }

  return intervalMark;
};
