import { takeEvery, takeLatest, all } from 'redux-saga/effects';
import {
  getVacancyAlarmsFailure,
  getVacancyAlarmsRequest,
  getVacancyAlarmsSuccess,
  deleteVacancyAlarmsFailure,
  deleteVacancyAlarmsRequest,
  deleteVacancyAlarmsSuccess,
  applyVacancyAlarmsFailure,
  applyVacancyAlarmsRequest,
  applyVacancyAlarmsSuccess,
} from 'store/reducers/vacancyAlarms';
import {
  getVacancyAlarms,
  requestVacancyAlarms,
  deleteVacancyAlarms,
} from 'api';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* getVacancyAlarmsSaga() {
  yield takeEvery(
    getVacancyAlarmsRequest.type,
    createFetchAction(
      getVacancyAlarms,
      getVacancyAlarmsSuccess,
      getVacancyAlarmsFailure,
      undefined,
      failure,
    ),
  );
}

function* requestVacancyAlarmsSaga() {
  yield takeLatest(
    applyVacancyAlarmsRequest.type,
    createFetchAction(
      requestVacancyAlarms,
      applyVacancyAlarmsSuccess,
      applyVacancyAlarmsFailure,
      function* success() {
        yield alert(
          `빈자리 알림이 신청되었습니다.\n빈자리 알림 신청 내역은 [My 캠핏 > 빈자리 알림 내역]에서 확인 가능합니다.`,
        );
      },
      failure,
    ),
  );
}

function* deleteVacancyAlarmsSaga() {
  yield takeLatest(
    deleteVacancyAlarmsRequest.type,
    createFetchAction(
      deleteVacancyAlarms,
      deleteVacancyAlarmsSuccess,
      deleteVacancyAlarmsFailure,
      function* success() {
        yield alert('신청 내역이 삭제되었습니다.');
        yield window.location.reload();
      },
      failure,
    ),
  );
}

function* vacancyAlarmSaga() {
  yield all([
    getVacancyAlarmsSaga(),
    requestVacancyAlarmsSaga(),
    deleteVacancyAlarmsSaga(),
  ]);
}

export default vacancyAlarmSaga;
