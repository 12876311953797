import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { App } from 'App';
import store from 'store';
import GlobalStyle from 'static/styles/GlobalStyle';
import { theme } from 'static/styles/theme';

const bizSdk = require('facebook-nodejs-business-sdk');

declare global {
  interface Document {
    documentMode?: any;
  }
}

// gtm 설정 초기화 / ywroh / 2022.03.29
const tagManagerArgs = {
  gtmId: 'GTM-PBQVWFZ',
};

TagManager.initialize(tagManagerArgs);

const accessToken = '{3af7a29b03b8ba3340c77d1afe91ec0c}'; // 토큰
const accountId = 'act_{{680751732890099}}'; // 광고 계정 ID

const FacebookAdsApi = bizSdk.FacebookAdsApi.init(accessToken);
const AdAccount = bizSdk.AdAccount;
const Campaign = bizSdk.Campaign;

const account = new AdAccount(accountId);
let campaigns;

account
  .read([AdAccount.Fields.name])
  .then((account: any) => {
    return account.getCampaigns([Campaign.Fields.name], { limit: 10 }); // fields array and params
  })
  .then((result: any) => {
    campaigns = result;
  })
  .catch(console.error);

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <CssBaseline />
            <App />
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root'),
);
