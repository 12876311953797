import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetProductShotsMoreRequestPayload, ProductState } from 'store/types';
import {
  TProductDetail,
  IArticle,
  IResponse,
  IPagingMedia,
  IProduct,
} from '@types';

const initialState: ProductState = {
  product: {} as TProductDetail,
  articles: [],
  isRequestSucceed: false,
  error: '',
  hasMoreShots: true,
  timestamp: 0,
  activeTab: 0,
  recommendProductList: [] as IProduct[],
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProductRequest: (state, action: PayloadAction<string>) => {
      state.product = {} as TProductDetail;
      state.error = '';
    },
    getProductSuccess: (state, action: PayloadAction<TProductDetail>) => {
      state.product = action.payload;
      state.error = '';
    },
    getProductFailure: (state, action: PayloadAction<Error | string>) => {
      state.product = {} as TProductDetail;
      state.error = action.payload;
    },

    getArticlesByProductRequest: (state, action: PayloadAction<string>) => {
      state.articles = [];
      state.error = '';
    },
    getArticlesByProductSuccess: (state, action: PayloadAction<IArticle[]>) => {
      state.articles = action.payload;
      state.error = '';
    },
    getArticlesByProductFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.articles = [];
      state.error = action.payload;
    },

    getProductShotsMoreRequest: (
      state,
      action: PayloadAction<IGetProductShotsMoreRequestPayload>,
    ) => {
      state.error = '';
    },
    getProductShotsMoreSuccess: (
      state,
      action: PayloadAction<IPagingMedia[]>,
    ) => {
      if (action.payload.length === 0) {
        state.hasMoreShots = false;
      } else {
        state.product.sampleReviewMedias =
          state.product.sampleReviewMedias.concat(action.payload);
      }
      state.error = '';
    },
    getProductShotsMoreFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
      state.hasMoreShots = false;
    },

    reviseProductInfoRequest: (
      state,
      action: PayloadAction<{ productId: string; text: string }>,
    ) => {
      state.isRequestSucceed = false;
      state.error = '';
    },
    reviseProductInfoSuccess: (state, action: PayloadAction<IResponse>) => {
      state.isRequestSucceed = true;
      state.error = '';
    },
    reviseProductInfoFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.isRequestSucceed = false;
      state.error = action.payload;
    },

    updateTimestamp: (
      state,
      action: PayloadAction<IGetProductShotsMoreRequestPayload>,
    ) => {
      state.timestamp = action.payload.lastTimestamp;
    },

    resetHasMoreShots: state => {
      state.hasMoreShots = true;
    },

    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },

    getRecommendProductListRequest: (
      state,
      action: PayloadAction<{ cat: string; p: number; ps: number }>,
    ) => {
      state.recommendProductList = [];
      state.error = '';
    },
    getRecommendProductListSuccess: (
      state,
      action: PayloadAction<{ numOfProducts: number; products: IProduct[] }>,
    ) => {
      state.recommendProductList = action.payload.products;
      state.error = '';
    },
    getRecommendProductListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.recommendProductList = [];
      state.error = action.payload;
    },
  },
});

export const {
  getArticlesByProductRequest,
  getArticlesByProductSuccess,
  getArticlesByProductFailure,
  getProductRequest,
  getProductSuccess,
  getProductFailure,
  getProductShotsMoreRequest,
  getProductShotsMoreSuccess,
  getProductShotsMoreFailure,
  reviseProductInfoRequest,
  reviseProductInfoSuccess,
  reviseProductInfoFailure,
  updateTimestamp,
  resetHasMoreShots,
  setActiveTab,

  getRecommendProductListRequest,
  getRecommendProductListSuccess,
  getRecommendProductListFailure,
} = productSlice.actions;

export default productSlice.reducer;
