import { takeLatest, all, select, take, put } from 'redux-saga/effects';
import { loginFailure, loginRequest, loginSuccess } from 'store/reducers/login';
import {
  logoutInAppFailure,
  logoutInAppRequest,
  logoutInAppSuccess,
  clickLogout,
} from 'store/reducers/logout';
import { login, logoutInApp } from 'api';
import { RootState } from 'store/reducers';
import { removeMyInfo } from 'store/reducers/myInfo';
import { failure } from 'store/sagas/failure';
import { createFetchAction } from 'store/sagas/createFetchAction';
import {
  getUserTrackingInfoRequest,
  resetUserTrackingInfo,
} from 'store/reducers/algoliaSearch';
import { ILogInResponse, IResponse } from '@types';
import { PayloadAction } from '@reduxjs/toolkit';
import { getCookie } from 'utils/cookie';
import { getSourcePath, removeSourcePath } from 'utils/sessionStorageUtils';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import { customHistory } from '../../App';

interface LocationState {
  isAuthorized?: boolean;
  from: { pathname: string };
}

function* loginSaga() {
  yield takeLatest(
    loginRequest.type,
    createFetchAction(
      login,
      loginSuccess,
      loginFailure,
      function* success(res: ILogInResponse) {
        const { jwt, user, isHibernating } = yield res;

        const { email, nickname, thumbnail, brief, role } = user;

        if (user.role.name !== 'Authenticated') {
          alert('캠지기센터 아이디는 사용하실 수 없습니다');
          return;
        }

        if (isHibernating) {
          sessionStorage.setItem('tempJwt', jwt);
          customHistory.replace('/dormant');
          return;
        }

        localStorage.setItem('jwt', jwt);
        localStorage.setItem('email', email);
        localStorage.setItem('nickname', nickname);
        brief && localStorage.setItem('brief', brief);
        yield put(getUserTrackingInfoRequest());

        if (thumbnail && thumbnail.url) {
          localStorage.setItem('thumbnail', thumbnail.url);
        }

        const { zone } = yield select(
          (state: RootState) => state.reservationReducer,
        );

        const sourcePath = getSourcePath();
        const locationState: LocationState = yield customHistory.location.state;

        if (sourcePath) {
          removeSourcePath();
          yield customHistory.push(sourcePath);
        } else if (customHistory.location.search) {
          const bookingId = customHistory.location.search?.split('=')[1];
          customHistory.push(`/reservation/result/${bookingId}`);
        } else if (locationState?.isAuthorized !== undefined) {
          customHistory.push('/reservation');
        } else if (zone?.id) {
          customHistory.push(`/camp/${zone?.camp.id}/${zone?.id}`);
        } else if (locationState?.from.pathname) {
          customHistory.push(locationState?.from.pathname);
        } else {
          customHistory.push('/');
        }
      },

      function* fail(error: Error | IResponse) {
        if (!localStorage.jwt) {
          if (error && error.message && typeof error.message === 'string') {
            yield alert(`${error.message}`);
          } else {
            yield alert('로그인 정보가 올바르지 않습니다.');
          }
          return;
        }
        yield failure(error);
      },
    ),
  );
}

function* logoutInAppSaga() {
  yield takeLatest(
    logoutInAppRequest.type,
    createFetchAction(
      logoutInApp,
      logoutInAppSuccess,
      logoutInAppFailure,
      function* success() {
        yield sessionStorage.clear();
        yield localStorage.clear();
        // 2022.3.23 변경된 로그아웃 로직에 앱에 반영이 안되어 앱에서도 push를 /mypage 에서 / 로 바꿈. / ywroh / 2022.03.29
        // NOTE. 안드로이드에서 로그아웃 되지 않는 문제 해결을 위해 하단 로직 주석 처리 하였습니다. / 이양우 / 2023.03.23
        // yield customHistory.push('/');
        yield put(resetUserTrackingInfo());
      },
      failure,
    ),
  );
}

function* logoutSaga() {
  while (true) {
    const action: PayloadAction<boolean> = yield take(clickLogout.type);

    if (action.payload) {
      const deviceId = getCookie('deviceId')();

      if (deviceId) {
        yield put(logoutInAppRequest(deviceId));
      } else {
        yield sessionStorage.clear();
        yield localStorage.clear();
        // 앱에서 로그아웃 처리가 잘 되지 않아 주석처리 하고, 웹과 같은 로직을 추가함. 앱에서 BootpayRNWebView 가 없는 경우가 있는지 확인 필요 / ywroh / 2022.03.29
        // yield customHistory.push('/mypage');
        yield put(removeMyInfo());
        yield window.location.replace('/');
      }
    } else {
      yield sessionStorage.clear();
      yield localStorage.clear();
      // 로그아웃 후 다른 계정으로 로그인시 뒤로가기하면 이전 로그인 정보가 나오는 경우가 있어 history push 를 삭제함. / ywroh / 2022.03.23
      // yield customHistory.push('/mypage');
      yield put(removeMyInfo());
      // 기존 모달창 link 를 통해 / 로 이동하니 뒤로가기하면 history goBack 함수를 통해 이전 로그인 정보가 남긴 페이지가 나옴. 따라서 기존 모달창 link 를 통해 / 이동하는 것을 아래 location replace 로 변경함. / ywroh / 2022.03.23
      yield window.location.replace('/');
    }
    yield put(resetUserTrackingInfo());
    yield sendGtmDataLayer({ user_id: undefined, crm_id: undefined });
  }
}

export default function* loginoutSaga() {
  yield all([loginSaga(), logoutInAppSaga(), logoutSaga()]);
}
