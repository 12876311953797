import { RegisterState, IResigterPayload } from 'store/types';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegisterResponse } from '@types';

const initialState: RegisterState = {
  step: 1,
  error: null,
  registerResponse: {} as IRegisterResponse,
  dormantInfo: {
    phone: '',
  },
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    goNextStep: state => {
      state.step += 1;
    },
    goPrevStep: state => {
      state.step -= 1;
    },

    registerRequest: (state, action: PayloadAction<IResigterPayload>) => {
      state.registerResponse = {} as IRegisterResponse;
      state.error = null;
    },
    registerSuccess: (state, action: PayloadAction<IRegisterResponse>) => {
      state.registerResponse = action.payload;
      state.error = null;
    },
    registerFailure: (state, action: PayloadAction<AxiosError>) => {
      state.registerResponse = {} as IRegisterResponse;
      state.error = action.payload;
    },
    resetRegisterRequest: state => {
      state.registerResponse = {} as IRegisterResponse;
      state.error = null;
    },

    goFirstStep: state => {
      state.step = 1;
    },

    getDormantUserPhoneRequest: state => {
      state.dormantInfo.phone = '';
      state.error = null;
    },
    getDormantUserPhoneSuccess: (
      state,
      action: PayloadAction<{ status: string; phone: string }>,
    ) => {
      state.dormantInfo.phone = action.payload.phone;
      state.error = null;
    },
    getDormantUserPhoneFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    resetDormantUserInfo: state => {
      state.dormantInfo.phone = '';
    },
  },
});

export const {
  goNextStep,
  goPrevStep,
  registerRequest,
  registerSuccess,
  registerFailure,
  resetRegisterRequest,
  goFirstStep,

  getDormantUserPhoneRequest,
  getDormantUserPhoneSuccess,
  getDormantUserPhoneFailure,

  resetDormantUserInfo,
} = registerSlice.actions;

export default registerSlice.reducer;
