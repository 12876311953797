import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductExhibitionState,
  IGetExhibitionProductsRqPayload,
} from 'store/types';
import { IProductExhibition, IExhibition, IProduct } from '@types';

const initialState: ProductExhibitionState = {
  productMainExhibitions: [],
  productExhibition: {} as IExhibition,
  products: [],
  offset: 0,
  hasMore: true,
  error: '',
};

export const productExhibitionSlice = createSlice({
  name: 'productExhibition',
  initialState,
  reducers: {
    getProductMainExhibitionsRequest: (state, action: PayloadAction) => {
      state.productMainExhibitions = [];
      state.error = '';
    },
    getProductMainExhibitionsSuccess: (
      state,
      action: PayloadAction<IProductExhibition[]>,
    ) => {
      state.productMainExhibitions = action.payload;
      state.error = '';
    },
    getProductMainExhibitionsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.productMainExhibitions = [];
      state.error = action.payload;
    },
    getSingleProductExhibitionRequest: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.productExhibition = {} as IExhibition;
      state.error = '';
    },
    getSingleProductExhibitionSuccess: (
      state,
      action: PayloadAction<IProductExhibition>,
    ) => {
      state.productExhibition = action.payload;
      state.error = '';
    },
    getSingleProductExhibitionFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.productExhibition = {} as IExhibition;
      state.error = action.payload;
    },
    getExhibitionProductsRequest: (
      state,
      action: PayloadAction<IGetExhibitionProductsRqPayload>,
    ) => {
      state.products = action.payload.skip ? state.products : [];
      state.offset = action.payload.skip;
      state.error = '';
    },
    getExhibitionProductsSuccess: (
      state,
      action: PayloadAction<IProduct[]>,
    ) => {
      state.products = state.products.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getExhibitionProductsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    addOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
  },
});

export const {
  getProductMainExhibitionsRequest,
  getProductMainExhibitionsSuccess,
  getProductMainExhibitionsFailure,
  getSingleProductExhibitionRequest,
  getSingleProductExhibitionSuccess,
  getSingleProductExhibitionFailure,
  getExhibitionProductsRequest,
  getExhibitionProductsSuccess,
  getExhibitionProductsFailure,
  addOffset,
} = productExhibitionSlice.actions;

export default productExhibitionSlice.reducer;
