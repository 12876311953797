import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  selfAuthenticationRequest,
  selfAuthenticationResultRequest,
  selfAuthenticationResultSuccess,
} from 'store/reducers/selfAuthentication';
import { getSelfAuthenticationResult } from 'api';
import { ISelfAuthenticationPayload } from 'store/types';
import { createSimpleAction } from './createFetchAction';

const BootPay = require('bootpay-js');

function processSelfAuthentication(params: {
  pageName: string;
  userInfo: {
    // username: string;
    phone: string;
  };
  functions: {
    onSuccess: (data: any) => void;
    onCancel: (data: any) => void;
    onError: (data: any) => void;
  };
}) {
  const id = Math.floor(Math.random() * 10000);

  BootPay.default
    .request({
      price: 0,
      application_id: process.env.REACT_APP_BOOTPAY_APPLICATION_ID as string,
      pg: 'danal',
      name: '본인인증',
      method: 'auth',
      show_agree_window: 0,
      order_id: `${Date.now()}${id}`,
      user_info: {
        phone: params.userInfo.phone,
      },
      extra: {
        age_limit: '14',
      },
    })
    .ready((data: any) => {
      // NOTE: 해당 함수 처리가 없으면, 앱에서 인증창이 뜨지 않음
      console.log(data);
    })
    .confirm((data: any) => {
      console.log(data);
      BootPay.default.transactionConfirm(data);
    })
    .error((data: any) => {
      console.log(data);
      params.functions.onError && params.functions.onError(data);
    })
    .cancel((data: any) => {
      console.log(data);
      params.functions.onCancel && params.functions.onCancel(data);
    })
    .done((data: ISelfAuthenticationPayload) => {
      params.functions.onSuccess(data);
    });
}
function* getSelfAuthenticationSaga() {
  yield takeLatest(
    selfAuthenticationRequest.type,
    function* process(action: PayloadAction<any>) {
      const { payload } = action;
      yield call(processSelfAuthentication, payload);
    },
  );
}

function* getSelfAuthenticationResultSaga() {
  yield takeLatest(
    selfAuthenticationResultRequest.type,
    createSimpleAction(
      getSelfAuthenticationResult,
      selfAuthenticationResultSuccess,
    ),
  );
}

export default function* selfAuthenticationSaga() {
  yield all([getSelfAuthenticationResultSaga(), getSelfAuthenticationSaga()]);
}
