import { PayloadAction } from '@reduxjs/toolkit';
import { all, takeEvery, take, put } from 'redux-saga/effects';
import {
  addOffset,
  getCamperItemsFailure,
  getCamperItemsRequest,
  getCamperItemsSuccess,
} from 'store/reducers/camperItems';
import { getCamperItems } from 'api';
import { IGetCamperItemsPayload } from 'store/types';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';

function* getCamperItemsSaga() {
  yield takeEvery(
    getCamperItemsRequest,
    createFetchAction(
      getCamperItems,
      getCamperItemsSuccess,
      getCamperItemsFailure,
    ),
  );
}

function* getMoreCamperItemsSaga() {
  while (true) {
    const action: PayloadAction<IGetCamperItemsPayload> = yield take(
      addOffset.type,
    );
    customHistory.location.pathname;

    yield put(getCamperItemsRequest(action.payload));
  }
}

export default function* camperItemsSaga() {
  yield all([getCamperItemsSaga(), getMoreCamperItemsSaga()]);
}
