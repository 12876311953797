import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'store/reducers';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';

const usePageViews = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem('jwt');

  const { trackingId } = useSelector(
    (state: RootState) => state.algoliaSearchReducer,
  );

  useEffect(() => {
    if (!isLoggedIn) {
      sendGtmDataLayer({
        event: 'pageview',
        user_id: undefined,
        crm_id: undefined,
      });
    }
  }, [location]);

  useEffect(() => {
    if (trackingId && isLoggedIn) {
      sendGtmDataLayer({
        event: 'pageview',
        user_id: trackingId,
        crm_id: trackingId,
      });
    }
  }, [location, trackingId]);
};

export default usePageViews;
