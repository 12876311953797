export const getCookie = (name: string) => () => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);

  return value ? value[2] : null;
};

export const setCookie = (name: string, value: number, day: number) => {
  const now = new Date();
  now.setTime(now.getTime() + day * 60 * 60 * 24 * 1000);

  document.cookie = `${name}=${value}; expires=${now.toUTCString()}; path=/`;
};
