import { createSlice } from '@reduxjs/toolkit';
import { InitState } from 'store/types';

const initialState: InitState = {
  isSagaInitCompleted: false,
};

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    permitFetching: state => {
      state.isSagaInitCompleted = true;
    },
  },
});

export const { permitFetching } = initSlice.actions;

export default initSlice.reducer;
