import Loading from 'pages/Loading';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router';
import ScrollToTop from 'hooks/useScrollToTop';
import IERender from 'pages/IERender';
import { createBrowserHistory } from 'history';
import { initAlgoliaRecommendIndex } from 'store/reducers/algoliaRecommend';
import { RootState } from 'store/reducers';
import { Product, Wrapper } from 'widgets/RootWrapper';
import PCContents from 'components/PCContents';
import SwitchRoute from 'SwitchRoute';
import NewToast from 'components/common/NewToast';
import {
  getUserTrackingInfoRequest,
  initAlgoliaInsight,
  initAlgoliaSearchIndex,
} from 'store/reducers/algoliaSearch';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import SEOHelmet from 'SEOHelmet';

export const customHistory = createBrowserHistory();

// Internet Explorer 6-11
const isIE =
  navigator.userAgent.indexOf('MSIE') !== -1 ||
  !!document.documentMode === true;

export const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem('jwt');
  const isSagaInitCompleted = useSelector(
    (state: RootState) => state.initReducer.isSagaInitCompleted,
  );

  const isLoadingUserTrackingInfo = useSelector(
    (state: RootState) => state.loadingReducer[getUserTrackingInfoRequest.type],
  );

  const isLoadinginitAlgoliaSearchIndex = useSelector(
    (state: RootState) => state.loadingReducer[initAlgoliaSearchIndex.type],
  );

  const { trackingId } = useSelector(
    (state: RootState) => state.algoliaSearchReducer,
  );

  useEffect(() => {
    if (isLoggedIn) dispatch(getUserTrackingInfoRequest());
    else sendGtmDataLayer({ algoliaUserToken: null });
  }, []);

  useEffect(() => {
    if (isLoadingUserTrackingInfo === false) {
      sendGtmDataLayer({ algoliaUserToken: trackingId || null });
    }
  }, [isLoadingUserTrackingInfo, trackingId]);

  useEffect(() => {
    dispatch(initAlgoliaSearchIndex('recommendDesc'));
  }, []);

  useEffect(() => {
    dispatch(initAlgoliaInsight());
  }, []);

  useEffect(() => {
    dispatch(initAlgoliaRecommendIndex());
  }, []);
  if (
    !isSagaInitCompleted ||
    isLoadingUserTrackingInfo ||
    isLoadinginitAlgoliaSearchIndex
  ) {
    return <Loading />;
  }

  document.oncontextmenu = () => {
    if (customHistory.location.pathname.includes('/log')) {
      return true;
    }

    if (!customHistory.location.pathname.includes('/board')) {
      return false;
    }
  };

  document.ondragstart = () => {
    if (
      customHistory.location.pathname.includes('/log') &&
      !customHistory.location.pathname.includes('/edit')
    ) {
      return true;
    }

    if (!customHistory.location.pathname.includes('/board')) {
      return false;
    }
  };

  document.onselectstart = () => {
    if (
      customHistory.location.pathname.includes('/log') &&
      !customHistory.location.pathname.includes('/edit')
    ) {
      return true;
    }

    if (!customHistory.location.pathname.includes('/board')) {
      return false;
    }
  };

  return (
    <Wrapper>
      <PCContents />
      <Product>
        {isIE ? (
          <IERoute />
        ) : (
          <Suspense fallback={<Loading />}>
            <Router history={customHistory}>
              <SEOHelmet />
              <ScrollToTop />
              <SwitchRoute />
              <NewToast />
            </Router>
          </Suspense>
        )}
      </Product>
    </Wrapper>
  );
};

const IERoute = () => {
  return (
    <Router history={customHistory}>
      <Switch>
        <Route component={IERender} path="/" />
      </Switch>
    </Router>
  );
};
