import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { all, call, put } from 'redux-saga/effects';
import getNoticesSaga from 'store/sagas/getNotices';
import exhibitionsSaga from 'store/sagas/exhibition';
import eventListSaga from 'store/sagas/event';
import { campSaga } from 'store/sagas/camp';
import { getMoreZonesSaga } from 'store/sagas/getMoreZones';
import {
  selectDateAndPeopleCntSaga,
  reservationSaga,
} from 'store/sagas/reservation';
import rootReducer from 'store/reducers';
import fetchLocationsSaga from 'store/sagas/fetchLocations';
import searchCampSaga from 'store/sagas/searchCamp';
import searchGeoCampSaga from 'store/sagas/searchGeoCamp';
import { fetchZoneDetailSaga } from 'store/sagas/fetchZoneDetail';
import { editPostSaga } from 'store/sagas/writeOnBoard';
import { IResponse } from '@types';
import { checkServerStatus } from 'api';
import { authenticateNumSaga, sendAuthenticationNumSaga } from './sagas/auth';
import { registerSaga } from './sagas/register';
import { getBoardSaga } from './sagas/getBoard';
import { likeSaga } from './sagas/like';
import { editCommentsSaga } from './sagas/writeComments';
import { commentsSaga } from './sagas/comments';
import { campingLogSaga } from './sagas/campingLog';
import { fetchMyListSaga } from './sagas/fetchMyList';
import reportBoardSaga from './sagas/reportBoard';
import deleteBoardSaga from './sagas/deleteBoard';
import bannerSaga from './sagas/banner';
import bannerListSaga from './sagas/bannerList';
import loginoutSaga from './sagas/loginout';
import { permitFetching } from './reducers/init';
import alarmSaga from './sagas/alarm';
import myInfoSaga from './sagas/myInfo';
import getCamperInfoSaga from './sagas/getCamperInfo';
import getOpenSchedulesSaga from './sagas/getOpenSchedules';
import couponSaga from './sagas/coupon';
import vacancyAlarmSaga from './sagas/vacancyAlarm';
import getCancelChargeSaga from './sagas/cancel';
import paymentSaga from './sagas/payment';
import communityBlockSaga from './sagas/communityBlock';
import fetchBestPhotosSaga from './sagas/fetchBestPhotos';
import getCampAnnouncesSaga from './sagas/getCampAnnounces';
import getZoneReservationStatusSaga from './sagas/getZoneReservationStatus';
import fetchMyReservationsSaga from './sagas/fetchMyReservations';
import articlesSaga from './sagas/articles';
import serviceInventorySaga from './sagas/serviceInventory';
import appPushSaga from './sagas/appPush';
import productPickSaga from './sagas/productPick';
import categorySaga from './sagas/category';
import myItemsSaga from './sagas/myItems';
import myItemsEditSaga from './sagas/myItemsEdit';
import { withdrawSaga } from './sagas/withdraw';
import { productSaga } from './sagas/product';
import productQnaSaga from './sagas/productQna';
import productReviewsSaga from './sagas/productReviews';
import camperItemsSaga from './sagas/camperItems';
import productExhibitionSaga from './sagas/productExhibition';
import productMainCategorySaga from './sagas/productMainCategory';
import productSearchSaga from './sagas/productSearch';
import autoCompleteSaga from './sagas/autoComplete';
import realtimeReservationSaga from './sagas/realtimeReservation';
import campsAutoCompleteSaga from './sagas/campsAutoComplete';
import {
  postPhoneAuthSaga,
  getNonReservationListSaga,
} from './sagas/getNonReservationList';
import reportUserSaga from './sagas/reportUser';
import bookmarkSaga from './sagas/bookmark';
import myCarNumberSaga from './sagas/myCarNumber';
import myPetsSaga from './sagas/myPets';
import hostsAndSuppliersSaga from './sagas/hostsAndSuppliers';
import selfAuthenticationSaga from './sagas/selfAuthentication';
import mainSaga from './sagas/main';
import v2CouponSaga from './sagas/v2Coupon';
import relatedProductListSaga from './sagas/RelatedProductList';
import algoliaSaga from './sagas/algolia';
import recommendProductListSaga from './sagas/recommendProductList';
import userAddressSaga from './sagas/userAddress';

const sagaMiddleware = createSagaMiddleware();

function* initSaga() {
  try {
    const res: IResponse = yield call(checkServerStatus);
    if (res.status === 'fail') {
      alert(res.message);
      return;
    }

    yield put(permitFetching());
  } catch (e) {
    yield console.log(e);
  }
}

export function* rootSaga() {
  yield initSaga();
  yield all([
    alarmSaga(),
    appPushSaga(),
    articlesSaga(),
    authenticateNumSaga(),
    autoCompleteSaga(),
    bannerSaga(),
    bannerListSaga(),
    bookmarkSaga(),
    camperItemsSaga(),
    campingLogSaga(),
    campsAutoCompleteSaga(),
    categorySaga(),
    commentsSaga(),
    communityBlockSaga(),
    couponSaga(),
    deleteBoardSaga(),
    eventListSaga(),
    exhibitionsSaga(),
    editCommentsSaga(),
    editPostSaga(),
    fetchBestPhotosSaga(),
    campSaga(),
    fetchLocationsSaga(),
    fetchMyListSaga(),
    fetchMyReservationsSaga(),
    fetchZoneDetailSaga(),
    getBoardSaga(),
    getCampAnnouncesSaga(),
    getCamperInfoSaga(),
    getCancelChargeSaga(),
    getMoreZonesSaga(),
    getNonReservationListSaga(),
    getNoticesSaga(),
    getOpenSchedulesSaga(),
    getZoneReservationStatusSaga(),
    likeSaga(),
    loginoutSaga(),
    myPetsSaga(),
    myCarNumberSaga(),
    myInfoSaga(),
    myItemsSaga(),
    myItemsEditSaga(),
    paymentSaga(),
    postPhoneAuthSaga(),
    productSaga(),
    productExhibitionSaga(),
    productMainCategorySaga(),
    productPickSaga(),
    productQnaSaga(),
    productReviewsSaga(),
    productSearchSaga(),
    relatedProductListSaga(),
    realtimeReservationSaga(),
    registerSaga(),
    reportBoardSaga(),
    reportUserSaga(),
    reservationSaga(),
    searchCampSaga(),
    searchGeoCampSaga(),
    selectDateAndPeopleCntSaga(),
    sendAuthenticationNumSaga(),
    serviceInventorySaga(),
    vacancyAlarmSaga(),
    withdrawSaga(),
    hostsAndSuppliersSaga(),
    selfAuthenticationSaga(),
    mainSaga(),
    v2CouponSaga(),
    algoliaSaga(),
    recommendProductListSaga(),
    userAddressSaga(),
  ]);
}

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export default store;
