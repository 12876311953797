import {
  deleteUserAddress,
  getUserAddressCount,
  getUserAddressList,
  setUserAddress,
  updateUserAddress,
} from 'api';
import { customHistory } from 'App';
import { toast } from 'react-toastify';
import { all, select, takeEvery } from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import {
  deleteUserAddressFailure,
  deleteUserAddressRequest,
  deleteUserAddressSuccess,
  getUserAddressCountFailure,
  getUserAddressCountRequest,
  getUserAddressCountSuccess,
  getUserAddressListFailure,
  getUserAddressListRequest,
  getUserAddressListSuccess,
  setUserAddressFailure,
  setUserAddressRequest,
  setUserAddressSuccess,
  updateUserAddressFailure,
  updateUserAddressRequest,
  updateUserAddressSuccess,
} from 'store/reducers/userAddress';
import { createFetchAction } from './createFetchAction';

function* getUserAddressCountSaga() {
  yield takeEvery(
    getUserAddressCountRequest.type,
    createFetchAction(
      getUserAddressCount,
      getUserAddressCountSuccess,
      getUserAddressCountFailure,
    ),
  );
}

function* getUserAddressListSaga() {
  yield takeEvery(
    getUserAddressListRequest.type,
    createFetchAction(
      getUserAddressList,
      getUserAddressListSuccess,
      getUserAddressListFailure,
    ),
  );
}

function* setUserAddressSaga() {
  yield takeEvery(
    setUserAddressRequest.type,
    createFetchAction(
      setUserAddress,
      setUserAddressSuccess,
      setUserAddressFailure,
      function* success() {
        const { count } = yield select(
          (state: RootState) => state.userAddressReducer,
        );

        yield window.sessionStorage.setItem('successType', 'set');
        yield window.sessionStorage.setItem('addressEdit', 'success');

        if (count) {
          yield customHistory.goBack();
        } else {
          yield customHistory.replace('/addressList');
        }
      },
    ),
  );
}

function* updateUserAddressSaga() {
  yield takeEvery(
    updateUserAddressRequest.type,
    createFetchAction(
      updateUserAddress,
      updateUserAddressSuccess,
      updateUserAddressFailure,
      function* success() {
        if (customHistory.location.pathname.includes('/addressList')) {
          yield toast.success('기본 배송지 설정 변경 완료');
        } else {
          yield customHistory.goBack();
          yield window.sessionStorage.setItem('successType', 'updateAddress');
        }

        yield window.sessionStorage.setItem('addressEdit', 'success');
      },
    ),
  );
}

function* deleteUserAddressSaga() {
  yield takeEvery(
    deleteUserAddressRequest.type,
    createFetchAction(
      deleteUserAddress,
      deleteUserAddressSuccess,
      deleteUserAddressFailure,
      function* sucess() {
        yield toast.success('배송 주소록 삭제 완료');
        yield window.sessionStorage.setItem('addressEdit', 'success');
      },
    ),
  );
}

export default function* userAddressSaga() {
  yield all([
    getUserAddressCountSaga(),
    getUserAddressListSaga(),
    setUserAddressSaga(),
    updateUserAddressSaga(),
    deleteUserAddressSaga(),
  ]);
}
