import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import aa from 'search-insights';
import {
  IAlgoliaProduct,
  IAlgoliaStoreFacet,
  IUserTrackingInfo,
  TProductSortType,
} from '@types';
import algoliasearch from 'algoliasearch';
import { ISortProductPayload } from 'store/types';

interface IAlgoriaSearch {
  searchClient: any;
  trackingId: string | undefined;
  productIndexRecords: any;
  algoliaProducts: IAlgoliaProduct[];
  algoliaProductBufferArray: IAlgoliaProduct[];
  algoliaStoreFacet: IAlgoliaStoreFacet;
  queryIDs: string[];
  sortedType: TProductSortType;
  words: Array<string>;
  totalCount: number;
  page: number;
  hasMore: boolean;
  error: string | Error;
}

const initialState: IAlgoriaSearch = {
  searchClient: null,
  trackingId: undefined,
  productIndexRecords: {},
  algoliaProducts: [],
  algoliaProductBufferArray: [],
  algoliaStoreFacet: {
    majorCategoryList: [],
    mediumCategoryList: [],
    minorCategoryList: [],
    brandList: [],
    priceMinValue: 0,
    priceMaxValue: 0,
  },
  queryIDs: [],
  sortedType: 'recommendDesc',
  words: [],
  totalCount: 0,
  page: 0,
  hasMore: true,
  error: '',
};

export const productIndexName = {
  recommendDesc: 'product',
  reviewDesc: 'product_review_desc',
  averageRateDesc: 'product_average_rate_desc',
  viewDesc: 'product_view_desc',
  priceDesc: 'product_price_desc',
  priceAsc: 'product_price_asc',
};
export const algoliaSearchSlice = createSlice({
  name: 'algoliaSearch',
  initialState,
  reducers: {
    initAlgoliaSearchIndex: (state, action: PayloadAction<string>) => {
      if (!state.searchClient) {
        state.searchClient = algoliasearch(
          process.env.REACT_APP_ALGOLIA_APP_ID as string,
          process.env.REACT_APP_ALGOLIA_API_KEY as string,
        );
      }
      state.productIndexRecords[action.payload] = state.searchClient.initIndex(
        productIndexName[action.payload as keyof typeof productIndexName],
      );
    },
    algoliaSearchProductRequest: (state, action: PayloadAction<any>) => {
      state.error = '';
    },
    algoliaSearchProductSuccess: (state, action: PayloadAction<any>) => {
      const { hits, page, nbHits, nbPages, queryID } = action.payload;
      state.algoliaProducts =
        page > 0 ? state.algoliaProducts.concat(hits) : hits;
      if (page === 0) state.queryIDs = [];
      state.queryIDs = [...state.queryIDs, queryID];
      state.page = page;
      state.totalCount = nbHits;
      state.hasMore = !(
        state.algoliaProducts.length >= nbHits || state.page >= nbPages
      );

      state.error = '';
    },
    resetAlgoliaSearchProduct: state => {
      state.algoliaProducts = [];
    },
    setAlgoliaProductBufferArray: (state, action: PayloadAction<any>) => {
      state.algoliaProductBufferArray = action.payload;
    },

    pushToProductBufferArrayRequest: state => {},

    pushToProductBufferArraySuccess: (state, action: PayloadAction<any>) => {
      const bufferArr = [];

      let bufferPushLimit = 4;

      for (
        let count = state.algoliaProductBufferArray.length;
        count < state.algoliaProducts.length;
        count += 1
      ) {
        if (bufferPushLimit <= 0) break;
        bufferArr.push(state.algoliaProducts[count]);
        bufferPushLimit -= 1;
      }

      state.algoliaProductBufferArray = [
        ...state.algoliaProductBufferArray,
        ...bufferArr,
      ];

      const objectIDs = [];
      for (const Product of bufferArr) {
        objectIDs.push(Product.objectID);
      }

      aa('viewedObjectIDs', {
        userToken: state.trackingId || undefined,
        index: productIndexName[state.sortedType],
        eventName: 'Viewed Object',
        objectIDs,
      });
    },

    pushToProductBufferArrayFailure: (state, action: PayloadAction<any>) => {},

    algoliaSearchProductFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    algoliaGetStoreFacetRequest: (state, action: PayloadAction<any>) => {
      state.error = '';
    },
    algoliaGetStoreFacetSuccess: (state, action: PayloadAction<any>) => {
      state.algoliaStoreFacet.majorCategoryList =
        action.payload.results[0].facetHits;
      state.algoliaStoreFacet.mediumCategoryList =
        action.payload.results[1].facetHits;
      state.algoliaStoreFacet.minorCategoryList =
        action.payload.results[2].facetHits;
      state.algoliaStoreFacet.brandList = action.payload.results[3].facetHits;
      state.algoliaStoreFacet.priceMinValue =
        action.payload.results[4].hits[0].priceStartFrom;
      state.algoliaStoreFacet.priceMaxValue =
        action.payload.results[5].hits[0].priceStartFrom;
    },
    algoliaGetStoreFacetFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    initAlgoliaInsight: state => {
      aa('init', {
        appId: process.env.REACT_APP_ALGOLIA_APP_ID as string,
        apiKey: process.env.REACT_APP_ALGOLIA_API_KEY as string,
        useCookie: !state.trackingId,
      });
    },
    getUserTrackingInfoRequest: state => {
      state.error = '';
    },
    getUserTrackingInfoSuccess: (
      state,
      action: PayloadAction<IUserTrackingInfo>,
    ) => {
      state.trackingId = action.payload.trackingId;
      state.error = '';
    },
    getUserTrackingInfoFailure: (state, action) => {
      state.error = action.payload;
    },
    resetUserTrackingInfo: state => {
      state.trackingId = '';
    },
    hardSortProducts: (state, action: PayloadAction<ISortProductPayload>) => {
      state.sortedType = action.payload.sort;
    },
  },
});

export const {
  initAlgoliaSearchIndex,
  algoliaSearchProductRequest,
  algoliaSearchProductSuccess,
  resetAlgoliaSearchProduct,
  algoliaSearchProductFailure,
  setAlgoliaProductBufferArray,
  pushToProductBufferArrayRequest,
  pushToProductBufferArraySuccess,
  pushToProductBufferArrayFailure,
  algoliaGetStoreFacetRequest,
  algoliaGetStoreFacetSuccess,
  algoliaGetStoreFacetFailure,
  initAlgoliaInsight,
  getUserTrackingInfoRequest,
  getUserTrackingInfoSuccess,
  getUserTrackingInfoFailure,
  resetUserTrackingInfo,
  hardSortProducts,
} = algoliaSearchSlice.actions;

export default algoliaSearchSlice.reducer;
