import {
  getReservationDate,
  setBasicPeopleCnt,
} from 'store/reducers/reservation';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  select,
  take,
  call,
  put,
  actionChannel,
  ActionPattern,
  takeEvery,
} from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import {
  startFetchingZone,
  getZoneRequest,
  getZoneSuccess,
  getZoneFailure,
  getAdditionalOptsRequest,
  getAdditionalOptsSuccess,
  getAdditionalOptsFailure,
} from 'store/reducers/zone';
import {
  getSiteCntFailure,
  getSiteCntRequest,
  getSiteCntSuccess,
  getSitesRequest,
  getSitesSuccess,
  getSitesFailure,
  getAvailableForOneNightRequest,
  getAvailableForOneNightSuccess,
  getAvailableForOneNightFailure,
} from 'store/reducers/site';
import { PeopleCnt, ReservationDate } from 'store/types';
import {
  getZoneDetail,
  getServicesByZone,
  countSitesByZone,
  findSitesByZone,
  getSitesAvailableForOneNight,
} from 'api';
import { fetchApi, createFetchAction } from './createFetchAction';
import { calculateDiffInDays } from '../../utils/calculateDate';

const queryString = require('query-string');

export function* getZone(id: string) {
  const peopleCnt: PeopleCnt = yield select(
    (state: RootState) => state.reservationReducer.peopleCnt,
  );
  const date: ReservationDate = yield select(
    (state: RootState) => state.reservationReducer.date,
  );
  const { adultCnt, teenCnt, childCnt } = peopleCnt;
  const { start, end } = date;

  yield put(getZoneRequest());
  yield call<any>(
    fetchApi,
    getZoneDetail,
    getZoneRequest.type,
    getZoneSuccess,
    getZoneFailure,
    {
      id,
      adult: adultCnt,
      teen: teenCnt,
      child: childCnt,
      startTimestamp: start ? start.getTime() : 0,
      endTimestamp: end ? end.getTime() : 0,
    },
  );
}

function* getAdditionalOpts(id: string) {
  yield put(getAdditionalOptsRequest());
  yield call<any>(
    fetchApi,
    getServicesByZone,
    getAdditionalOptsRequest.type,
    getAdditionalOptsSuccess,
    getAdditionalOptsFailure,
    { id, limit: 100, skip: 0 },
  );
}

export function* getSites(id: string) {
  const date: ReservationDate = yield select(
    (state: RootState) => state.reservationReducer.date,
  );
  const { start, end } = date;

  if (start && end) {
    yield put(getSitesRequest());
    yield call<any>(
      fetchApi,
      findSitesByZone,
      getSitesRequest.type,
      getSitesSuccess,
      getSitesFailure,
      {
        id,
        startTimestamp: start ? start.getTime() : 0,
        endTimestamp: end ? end.getTime() : 0,
      },
    );
  }
}

function* fetchZone() {
  const subChannel: ActionPattern = yield actionChannel(startFetchingZone.type);
  while (true) {
    const action: PayloadAction<string> = yield take(subChannel);

    const parsed = queryString.parse(window.location.search);

    if (parsed.startTimestamp && parsed.endTimestamp) {
      yield put(
        getReservationDate({
          start: new Date(+parsed.startTimestamp),
          end: new Date(+parsed.endTimestamp),
        }),
      );

      yield put(setBasicPeopleCnt());
    }

    yield call(getZone, action.payload);

    yield call(getAdditionalOpts, action.payload);

    yield put(getSiteCntRequest());
    yield call<any>(
      fetchApi,
      countSitesByZone,
      getSiteCntRequest.type,
      getSiteCntSuccess,
      getSiteCntFailure,
      action.payload,
    );

    yield call(getSites, action.payload);
  }
}

export function* fetchZoneDetailSaga() {
  yield all([
    fetchZone(),
    takeEvery(
      getAvailableForOneNightRequest.type,
      createFetchAction(
        getSitesAvailableForOneNight,
        getAvailableForOneNightSuccess,
        getAvailableForOneNightFailure,
      ),
    ),
  ]);
}
