import {
  IAlgoliaGtmDataLayer,
  ICouponGtmDataLayer,
  IGtmDataLayer,
  IUserTrackingGtmDataLayer,
  TCouponIssuance,
} from '@types';
import TagManager from 'react-gtm-module';

const sendGtmDataLayer = (
  gtmDataLayer:
    | IGtmDataLayer
    | ICouponGtmDataLayer
    | IAlgoliaGtmDataLayer
    | IUserTrackingGtmDataLayer,
) => {
  const tagManagerArgs = {
    dataLayer: gtmDataLayer,
  };
  /**
  /* NOTE: GTM 디버깅, 2가지 방법 선택 진행 후, 실제 GA 디버깅은 배포 후 담당자님이 진행 / 보라 (c.c 형준) / 22.12.14
  /* 1. sendGtmDataLayer 함수 내 콘솔 로그, console.log(gtmDataLayer);
  /* 2. 이벤트 발생 시점 이후 브라우저 콘솔에서 window.dataLayer 출력 시, dataLayer 배열에 해당 이벤트 추가됐는지 확인가능
  */
  TagManager.dataLayer(tagManagerArgs);
};

export default sendGtmDataLayer;

export const sendCouponGtmDataLayer = (coupon: {
  camp: string;
  name: string;
  type: 'zone' | 'camp';
  masterCode: string;
  issuance: TCouponIssuance;
  price: string;
}) => {
  let couponType;

  if (coupon.type === 'zone') {
    couponType = '일부 캠핑존';
  } else {
    couponType = '전체 캠핑존';
  }

  sendGtmDataLayer({
    event: 'coupon_down',
    coupon_camp: coupon.camp,
    coupon_name: coupon.name,
    coupon_type: couponType,
    coupon_code: coupon.masterCode,
    coupon_issuance: coupon.issuance,
    coupon_price: coupon.price,
  });
};
