import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserAddress } from '@types';
import {
  ISetUserAddressRequestPayload,
  IUpdateUserAddressRequestPayload,
  TUserAddressState,
} from 'store/types';

const initialState: TUserAddressState = {
  addressList: [] as IUserAddress[],
  count: 0,
  page: 1,
  pageSize: 0,
  error: '',
};

export const userAddressSlice = createSlice({
  name: 'userAddress',
  initialState,
  reducers: {
    getUserAddressCountRequest: (state, action: PayloadAction) => {
      state.count = 0;
      state.error = '';
    },
    getUserAddressCountSuccess: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      state.error = '';
    },
    getUserAddressCountFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.count = 0;
      state.error = action.payload;
    },

    getUserAddressListRequest: (state, action: PayloadAction) => {
      state.addressList = [];
      state.error = '';
    },
    getUserAddressListSuccess: (
      state,
      action: PayloadAction<IUserAddress[]>,
    ) => {
      state.addressList = state.addressList.concat(action.payload);
      state.error = '';
    },
    getUserAddressListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.addressList = [];
      state.error = action.payload;
    },

    addPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setUserAddressRequest: (
      state,
      action: PayloadAction<ISetUserAddressRequestPayload>,
    ) => {
      state.error = '';
    },
    setUserAddressSuccess: (state, action: PayloadAction) => {
      state.error = '';
    },
    setUserAddressFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    updateUserAddressRequest: (
      state,
      action: PayloadAction<IUpdateUserAddressRequestPayload>,
    ) => {
      state.error = '';
    },
    updateUserAddressSuccess: (state, action: PayloadAction) => {
      state.error = '';
    },
    updateUserAddressFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    deleteUserAddressRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    deleteUserAddressSuccess: (state, action: PayloadAction) => {
      state.error = '';
    },
    deleteUserAddressFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    updateAddressCardList: (
      state,
      action: PayloadAction<{
        option: 'delete' | 'setMainAddress';
        targetAddressId?: string;
        subTargetAddressId?: string;
      }>,
    ) => {
      const { option, targetAddressId, subTargetAddressId } = action.payload;

      const targetAddressCardIndex = state.addressList.findIndex(
        address => address.id === targetAddressId,
      );
      const subTargetAddressCardIndex = state.addressList.findIndex(
        address => address.id === subTargetAddressId,
      );

      if (targetAddressCardIndex === -1) {
        return;
      }

      if (option === 'setMainAddress') {
        if (subTargetAddressCardIndex === -1) {
          return;
        }
        const copyAddressList = [...state.addressList];
        copyAddressList[targetAddressCardIndex].isMain = true;
        copyAddressList[subTargetAddressCardIndex].isMain = false;

        state.addressList = [...copyAddressList];
      }

      if (option === 'delete') {
        const copyAddressList = [...state.addressList];
        copyAddressList.splice(targetAddressCardIndex, 1);

        state.addressList = [...copyAddressList];
      }
    },
  },
});

export const {
  getUserAddressCountRequest,
  getUserAddressCountSuccess,
  getUserAddressCountFailure,

  getUserAddressListRequest,
  getUserAddressListSuccess,
  getUserAddressListFailure,
  addPage,

  setUserAddressRequest,
  setUserAddressSuccess,
  setUserAddressFailure,

  updateUserAddressRequest,
  updateUserAddressSuccess,
  updateUserAddressFailure,

  deleteUserAddressRequest,
  deleteUserAddressSuccess,
  deleteUserAddressFailure,

  updateAddressCardList,
} = userAddressSlice.actions;

export default userAddressSlice.reducer;
