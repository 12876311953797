import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPagination, TUserEnv, IBookmark } from '@types';
import { ICampGtmInfo, IRemoveFromCart } from 'gtm/types';
import {
  BookmarkState,
  IAppPushOnOffPayload,
  IMyBookmark,
  IMyBookmarkMorePayload,
  IMyBookmarkPayload,
} from 'store/types';

const initialState: BookmarkState = {
  userEnv: '',
  error: '',
  myBookmarks: [],
  /* NOTE: isBookmarkChanged
  북마크 데이터를 새로 불러올지 구분하는 변수, 
  북마크(찜)에 변동이 있을 때(=현재는 찜 목록이 추가되었을 때) true이며, 
  북마크 전체 데이터 새로 불러오게 됨  
  */
  isBookmarkChanged: undefined,
  myBookmarksPagination: {
    wholeCount: undefined,
    skip: 0,
    limit: 5,
  } as IPagination,
  featureStatus: 'initial',
  needAppInstall: undefined,
  isAppUser: false,
};

export const bookmarkSlice = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {
    createBookmarkRequest: (
      state: BookmarkState,
      action: PayloadAction<{ camp: ICampGtmInfo }>,
    ) => {
      state.error = '';
    },
    createBookmarkSuccess: (
      state: BookmarkState,
      action: PayloadAction<IBookmark>,
    ) => {
      state.isAppUser = action.payload.isAppUser;
      state.error = '';
    },
    createBookmarkFailure: (
      state: BookmarkState,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    deleteBookmarkRequest: (
      state: BookmarkState,
      action: PayloadAction<{ campId: string; campName: string }>,
    ) => {
      state.error = '';
    },
    deleteBookmarkSuccess: (
      state: BookmarkState,
      action: PayloadAction<any>,
    ) => {
      state.error = '';
      state.myBookmarks = state.myBookmarks.filter(
        myBookmark => myBookmark.camp.id !== action.payload.campid,
      );
    },
    deleteBookmarkFailure: (
      state: BookmarkState,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    setUserEnv: (state: BookmarkState, action: PayloadAction<TUserEnv>) => {
      state.userEnv = action.payload;
    },
    getMyBookmarksRequest: (
      state: BookmarkState,
      action: PayloadAction<IMyBookmarkPayload>,
    ) => {
      state.myBookmarksPagination.skip = action.payload.skip;
      state.myBookmarksPagination.limit = action.payload.limit;
      state.myBookmarks = [];
    },
    getMyBookmarksMoreRequest: (
      state: BookmarkState,
      action: PayloadAction<IMyBookmarkMorePayload>,
    ) => {
      action.payload.skip = state.myBookmarksPagination.skip;
      action.payload.limit = state.myBookmarksPagination.limit;
    },

    getMyBookmarksSuccess: (
      state: BookmarkState,
      action: PayloadAction<IMyBookmark[]>,
    ) => {
      state.myBookmarksPagination.skip =
        state.myBookmarksPagination.limit + state.myBookmarksPagination.skip;
      state.myBookmarks = [...state.myBookmarks, ...action.payload];
    },
    getMyBookmarksFailure: (
      state: BookmarkState,
      action: PayloadAction<Error | string>,
    ) => {
      state.myBookmarksPagination.skip =
        state.myBookmarksPagination.limit + state.myBookmarksPagination.skip;
      state.error = action.payload;
    },

    getMyBookmarkCountRequest: (
      state: BookmarkState,
      action: PayloadAction<{ forceUpdate: boolean | undefined }>,
    ) => {},
    getMyBookmarkCountSuccess: (
      state: BookmarkState,
      action: PayloadAction<{ count: number; forceUpdate?: boolean }>,
    ) => {
      state.myBookmarksPagination.wholeCount = action.payload.count;
    },
    getMyBookmarkCountFailure: (
      state: BookmarkState,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    deleteBulkBookmarkRequest: (
      state: BookmarkState,
      action: PayloadAction<{
        campIds: string[];
        RemoveFromCartItems: IRemoveFromCart[];
      }>,
    ) => {
      state.featureStatus = 'loading';
    },
    deleteBulkBookmarkSuccess: (
      state: BookmarkState,
      action: PayloadAction<{
        campIds: string[];
      }>,
    ) => {
      state.myBookmarks = state.myBookmarks.filter(
        bookmark => !action.payload.campIds.includes(bookmark.camp.id),
      );
      state.featureStatus = 'success';
    },
    deleteBulkBookmarkFailure: (
      state: BookmarkState,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
      state.featureStatus = 'fail';
    },
    changeAppPushOnOffRequest: (
      state: BookmarkState,
      action: PayloadAction<string>,
    ) => {
      state.featureStatus = 'loading';
      state.needAppInstall = undefined;
    },
    changeAppPushOnOffSuccess: (
      state: BookmarkState,
      action: PayloadAction<IAppPushOnOffPayload>,
    ) => {
      state.featureStatus = action.payload.status;
      state.needAppInstall = action.payload.needAppInstall ?? false;
      state.myBookmarks = state.myBookmarks.map(bookmark => {
        if (bookmark.id === action.payload.changedBookmarkId) {
          bookmark.isPushAlarmOn = !bookmark.isPushAlarmOn;
        }
        return bookmark;
      });
    },
    changeAppPushOnOffFailure: (
      state: BookmarkState,
      action: PayloadAction<IAppPushOnOffPayload>,
    ) => {
      state.featureStatus = 'fail';
      state.needAppInstall = action.payload.needAppInstall;
      if (action.payload.message) state.error = action.payload.message;
    },
    changeAppPushOnOffError: (
      state: BookmarkState,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    updateAppPushOnOff: (
      state: BookmarkState,
      action: PayloadAction<{ selectedBookmarkId: string }>,
    ) => {
      state.myBookmarks = state.myBookmarks.map(bookmark => {
        if (bookmark.id === action.payload.selectedBookmarkId) {
          bookmark.isPushAlarmOn = !bookmark.isPushAlarmOn;
        }
        return bookmark;
      });
    },
    resetFeatureStatus: (state: BookmarkState) => {
      state.featureStatus = 'initial';
    },
    setIsBookmarkChanged: (
      state: BookmarkState,
      action: PayloadAction<boolean>,
    ) => {
      state.isBookmarkChanged = action.payload;
    },

    deleteBookmarkByCampId: (
      state: BookmarkState,
      action: PayloadAction<{ campid: string }>,
    ) => {
      state.myBookmarks = state.myBookmarks.filter(
        myBookmark => myBookmark.camp.id !== action.payload.campid,
      );
      if (state.myBookmarksPagination.wholeCount)
        state.myBookmarksPagination.wholeCount -= 1;
    },
    deleteBookmarks: (
      state: BookmarkState,
      action: PayloadAction<{ campIds: string[] }>,
    ) => {
      if (state.myBookmarksPagination.wholeCount)
        state.myBookmarksPagination.wholeCount -= action.payload.campIds.length;
      state.myBookmarks = state.myBookmarks.filter(
        bookmark => !action.payload.campIds.includes(bookmark.camp.id),
      );
    },
    resetNeedAppInstall: (
      state: BookmarkState,
      action: PayloadAction<boolean>,
    ) => {
      state.needAppInstall = action.payload;
    },
  },
});

export const {
  createBookmarkRequest,
  createBookmarkSuccess,
  createBookmarkFailure,
  deleteBookmarkRequest,
  deleteBookmarkSuccess,
  deleteBookmarkFailure,
  setUserEnv,
  getMyBookmarksRequest,
  getMyBookmarksSuccess,
  getMyBookmarksFailure,
  getMyBookmarksMoreRequest,
  getMyBookmarkCountRequest,
  getMyBookmarkCountSuccess,
  getMyBookmarkCountFailure,
  deleteBulkBookmarkRequest,
  deleteBulkBookmarkSuccess,
  deleteBulkBookmarkFailure,
  changeAppPushOnOffRequest,
  changeAppPushOnOffSuccess,
  changeAppPushOnOffFailure,
  changeAppPushOnOffError,
  resetFeatureStatus,
  updateAppPushOnOff,
  setIsBookmarkChanged,
  deleteBookmarkByCampId,
  deleteBookmarks,
  resetNeedAppInstall,
} = bookmarkSlice.actions;

export default bookmarkSlice.reducer;
