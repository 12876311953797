import blank from 'static/images/blank/blank.jpeg';

export const findOptimalImage = (
  data: any,
  minWidth: number,
  defaultImage?: any,
) => {
  const defImg = defaultImage || blank;
  if (!data) {
    return defImg;
  }
  if (typeof data === 'string') {
    return data;
  }
  if (data.buffer) {
    return data.buffer;
  }
  if (data.thumbnail && !data.thumbnail.url) {
    const m = data.thumbnail;
    if (m.formats) {
      if (m.formats.thumbnail && m.formats.thumbnail.width > minWidth) {
        return m.formats.thumbnail.url;
      }
      if (m.formats.small && m.formats.small.width > minWidth) {
        return m.formats.small.url;
      }
      if (m.formats.medium && m.formats.medium.width > minWidth) {
        return m.formats.medium.url;
      }
      if (m.formats.large && m.formats.large.width > minWidth) {
        return m.formats.large.url;
      }
    }
    if (m.url) {
      return m.url;
    }
  }
  if (data.formats) {
    if (data.formats.thumbnail && data.formats.thumbnail.width > minWidth) {
      return data.formats.thumbnail.url;
    }
    if (data.formats.small && data.formats.small.width > minWidth) {
      return data.formats.small.url;
    }
    if (data.formats.medium && data.formats.medium.width > minWidth) {
      return data.formats.medium.url;
    }
    if (data.formats.large && data.formats.large.width > minWidth) {
      return data.formats.large.url;
    }
    if (data.url) {
      return data.url;
    }
  }
  if (data.media) {
    if (data.media.formats) {
      if (
        data.media.formats.thumbnail &&
        data.media.formats.thumbnail.width > minWidth
      ) {
        return data.media.formats.thumbnail.url;
      }
      if (
        data.media.formats.small &&
        data.media.formats.small.width > minWidth
      ) {
        return data.media.formats.small.url;
      }
      if (
        data.media.formats.medium &&
        data.media.formats.medium.width > minWidth
      ) {
        return data.media.formats.medium.url;
      }
      if (
        data.media.formats.large &&
        data.media.formats.large.width > minWidth
      ) {
        return data.media.formats.large.url;
      }
    }
    if (data.media.url) {
      return data.media.url;
    }
  }
  if (data.medias && Array.isArray(data.medias)) {
    const m = data.medias[0];
    if (m.formats) {
      if (m.formats.thumbnail && m.formats.thumbnail.width > minWidth) {
        return m.formats.thumbnail.url;
      }
      if (m.formats.small && m.formats.small.width > minWidth) {
        return m.formats.small.url;
      }
      if (m.formats.medium && m.formats.medium.width > minWidth) {
        return m.formats.medium.url;
      }
      if (m.formats.large && m.formats.large.width > minWidth) {
        return m.formats.large.url;
      }
    }
    if (m.url) {
      return m.url;
    }
  }
  if (data.url) {
    return data.url;
  }
  return defImg;
};

const findMatchRatio = (f: any, minWidth: number, minHeight: number) => {
  if (
    f.thumbnail &&
    f.thumbnail.width > minWidth &&
    f.thumbnail.height > minHeight
  ) {
    return {
      imgUrl: f.thumbnail.url,
      imgWidth: f.thumbnail.width,
      imgHeight: f.thumbnail.height,
    };
  }
  if (f.small && f.small.width > minWidth && f.small.height > minHeight) {
    return {
      imgUrl: f.small.url,
      imgWidth: f.small.width,
      imgHeight: f.small.height,
    };
  }
  if (f.medium && f.medium.width > minWidth && f.medium.height > minHeight) {
    return {
      imgUrl: f.medium.url,
      imgWidth: f.medium.width,
      imgHeight: f.medium.height,
    };
  }
  if (f.large && f.large.width > minWidth && f.large.height > minHeight) {
    return {
      imgUrl: f.large.url,
      imgWidth: f.large.width,
      imgHeight: f.large.height,
    };
  }
  return false;
};

export const findOptimalRatioImage = (
  data: any,
  minWidth: number,
  minHeight: number,
  defaultImage?: any,
) => {
  const defImg = defaultImage || blank;
  const imgWidth = minWidth;
  const imgHeight = minHeight;
  if (!data) {
    return { imgUrl: defImg, imgWidth, imgHeight };
  }
  if (typeof data === 'string') {
    return { imgUrl: data, imgWidth, imgHeight };
  }
  if (data.buffer) {
    return { imgUrl: data.buffer, imgWidth, imgHeight };
  }
  if (data.thumbnail && !data.thumbnail.url) {
    if (data.thumbnail.formats) {
      const isMatched = findMatchRatio(
        data.thumbnail.formats,
        minWidth,
        minHeight,
      );
      if (isMatched) return isMatched;
    }
    if (data.thumbnail.url) {
      return { imgUrl: data.thumbnail.url, imgWidth, imgHeight };
    }
  }
  if (data.formats) {
    const isMatched = findMatchRatio(data.formats, minWidth, minHeight);
    if (isMatched) return isMatched;
    if (data.url) {
      return { imgUrl: data.url, imgWidth, imgHeight };
    }
  }
  if (data.media) {
    if (data.media.formats) {
      const isMatched = findMatchRatio(data.media.formats, minWidth, minHeight);
      if (isMatched) return isMatched;
    }
    if (data.media.url) {
      return { imgUrl: data.media.url, imgWidth, imgHeight };
    }
  }
  if (data.medias && Array.isArray(data.medias)) {
    if (data.medias[0].formats) {
      const isMatched = findMatchRatio(
        data.medias[0].formats,
        minWidth,
        minHeight,
      );
      if (isMatched) return isMatched;
    }
    if (data.medias[0].url) {
      return { imgUrl: data.medias[0].url, imgWidth, imgHeight };
    }
  }
  if (data.url) {
    return { imgUrl: data.url, imgWidth, imgHeight };
  }
  return { imgUrl: defImg, imgWidth, imgHeight };
};
