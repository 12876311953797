import axios, { AxiosInstance, AxiosRequestConfig, AxiosAdapter } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import store from 'store';
import { clickLogout } from 'store/reducers/logout';
import { getServerURL } from '../server';

const instance: AxiosInstance = axios.create({
  baseURL: getServerURL(),
  headers: { 'Cache-Control': 'no-cache' },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
    enabledByDefault: false,
  }),
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  let jwt = localStorage.getItem('jwt');

  if (jwt) {
    jwt = jwt.replace(/"/g, '');
  }

  config.headers.Authorization = jwt ? `Bearer ${jwt}` : '';
  return config;
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      if (localStorage.getItem('jwt')) {
        alert('세션이 만료되었습니다. 다시 로그인 해주세요.');
        store.dispatch(clickLogout(!!window.BootpayRNWebView));
      }
    }

    if (error.response.status === 403) {
      alert('권한이 없습니다. 로그인이 필요합니다.');
      // NOTE: login 페이지로 했을 때 무한 alert 현상 생긴 사례가 있어 현재 mypage로 유지 > 추후 확인해서 픽스 후 수정해야할 듯
      window.location.href = '/mypage';
    }

    return Promise.reject(error);
  },
);

export default instance;
