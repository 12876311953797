import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BannerListState, IBannerListRequestPayload } from 'store/types';
import { IBanner, ILandingPage, IProductMainBanners, TPlaceType } from '@types';

const initialState: BannerListState = {
  page: {
    name: '',
    dataList: [],
    paging: {
      hasMore: true,
      skip: 0,
      limit: 0,
    },
    error: '',
  },
  banners: {
    myPage: [],
    // 하단 배너 리스트는 v1에서 사용되는 배너 목록인데, 예시로만 두었고 아직 v2 적용 못하였음.
    pending: [],
    community: [],
    product: {
      data: {} as IProductMainBanners,
      dataList: [],
    },
  },
  landingImage: {} as ILandingPage,
  error: '',
};

export const bannerListSlice = createSlice({
  name: 'bannerList',
  initialState,
  reducers: {
    getBannerListRequest: (
      state,
      action: PayloadAction<IBannerListRequestPayload>,
    ) => {
      if (state.page.name !== action.payload.name) {
        state.page.name = action.payload.name;
        state.page.dataList = [];
      } else if (action.payload.skip === 0) {
        state.page.dataList = [];
      }
      state.page.paging.limit = action.payload.limit;
    },

    getBannerListSuccess: (state, action: PayloadAction<IBanner[]>) => {
      state.page.dataList = action.payload;
      // NOTE: whole count 값이 없으므로 가져온 데이터 개수로 끝인지 판단.
      state.page.paging.hasMore =
        action.payload.length === state.page.paging.limit;
      state.page.paging.skip += state.page.paging.limit;
    },

    getBannerListFailure: (state, action: PayloadAction<Error | string>) => {
      state.page.dataList = [];
      state.page.error = action.payload;
    },

    addBannerListOffset: state => {},

    getBannersRequest: (
      state,
      action: PayloadAction<{
        key: keyof typeof initialState.banners;
        place: TPlaceType['value'];
      }>,
    ) => {
      const { key } = action.payload;
      state.banners[key] = [] as IBanner[];
      state.error = '';
    },
    getBannersSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof initialState.banners;
        data: IBanner[];
      }>,
    ) => {
      const { key, data } = action.payload;
      state.banners[key] = data;
      state.error = '';
    },
    getBannersFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    getLandingRequest: (state, action: PayloadAction<string>) => {
      state.landingImage = {} as ILandingPage;
      state.error = '';
    },
    getLandingSuccess: (state, action: PayloadAction<ILandingPage>) => {
      state.landingImage = action.payload;
      state.error = '';
    },
    getLandingFailure: (state, action: PayloadAction<Error | string>) => {
      state.landingImage = {} as ILandingPage;
      state.error = action.payload;
    },
  },
});

export const {
  getBannerListRequest,
  getBannerListSuccess,
  getBannerListFailure,
  addBannerListOffset,
  getBannersRequest,
  getBannersSuccess,
  getBannersFailure,
  getLandingRequest,
  getLandingSuccess,
  getLandingFailure,
} = bannerListSlice.actions;

export default bannerListSlice.reducer;
