import { takeEvery, all, take, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getMyReservationsRequest,
  getMyReservationsSuccess,
  getMyReservationsFailure,
  getMyReservationsCntRequest,
  getMyReservationsCntSuccess,
  getMyReservationsCntFailure,
  addOffset,
  getNonMemeberBookingTokenRequest,
  getNonMemeberBookingTokenSuccess,
  getNonMemeberBookingTokenFailure,
  setReservationStatus,
} from 'store/reducers/myReservations';
import { RootState } from 'store/reducers';
import { findBookings, countBookings, findBookingToken } from 'api';
import { IGetNonMemberBookingToken, IMyReservationStatus } from '@types';
import { myReservationListLnb } from 'constants/lnbConstants';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';
import { failure } from './failure';

function* getNonMemberBookingTokenSaga() {
  yield takeEvery(
    getNonMemeberBookingTokenRequest.type,
    createFetchAction(
      findBookingToken,
      getNonMemeberBookingTokenSuccess,
      getNonMemeberBookingTokenFailure,
      function* success(res: IGetNonMemberBookingToken) {
        yield customHistory.push(
          `/reservation/result/${res.bookingId}?token=${res.token}`,
        );
      },
      failure,
    ),
  );
}

function* getMyReservationsSaga() {
  yield takeEvery(
    getMyReservationsRequest.type,
    createFetchAction(
      findBookings,
      getMyReservationsSuccess,
      getMyReservationsFailure,
      undefined,
      failure,
    ),
  );
}

function* getMyReservationsCntSaga() {
  yield takeEvery(
    getMyReservationsCntRequest.type,
    createFetchAction(
      countBookings,
      getMyReservationsCntSuccess,
      getMyReservationsCntFailure,
    ),
  );
}

function* getMoreMyReservationsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addOffset.type);

    const activeTab: number = yield select(
      (state: RootState) => state.myReservationsReducer.activeTab,
    );

    yield put(
      getMyReservationsRequest({
        skip: action.payload,
        limit: 10,
        isReviewWritten: customHistory.location.pathname.includes('edit')
          ? false
          : null,
        status: customHistory.location.pathname.includes('edit')
          ? 'checkout'
          : (Object.keys(myReservationListLnb) as IMyReservationStatus[])[
              activeTab
            ],
      }),
    );
  }
}

function* getMyReservationByStatusSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(setReservationStatus.type);

    yield put(
      getMyReservationsRequest({
        skip: 0,
        limit: 10,
        isReviewWritten: null,
        status: (Object.keys(myReservationListLnb) as IMyReservationStatus[])[
          action.payload
        ],
      }),
    );

    yield put(
      getMyReservationsCntRequest({
        isReviewWritten: null,
        status: (Object.keys(myReservationListLnb) as IMyReservationStatus[])[
          action.payload
        ],
      }),
    );
  }
}

export default function* fetchMyReservationsSaga() {
  yield all([
    getMyReservationsSaga(),
    getMyReservationsCntSaga(),
    getMoreMyReservationsSaga(),
    getNonMemberBookingTokenSaga(),
    getMyReservationByStatusSaga(),
  ]);
}
