import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SiteState, IGetAvailableForOneNightPayload } from 'store/types';
import { ISite, IGetSitesAvailableForOneNightResponse } from '@types';

const initialState: SiteState = {
  siteCnt: 0,
  siteList: [],
  sitesAvailableForOneNight: [],
  error: null,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    getSiteCntRequest: state => {
      state.siteCnt = 0;
      state.siteList = [];
      state.error = null;
    },
    getSiteCntSuccess: (state, action: PayloadAction<number>) => {
      state.siteCnt = action.payload;
      state.error = null;
    },
    getSiteCntFailure: (state, action: PayloadAction<AxiosError>) => {
      state.siteCnt = 0;
      state.error = action.payload;
    },
    getSitesRequest: state => {
      state.siteList = [];
      state.sitesAvailableForOneNight = [];
      state.error = null;
    },
    getSitesSuccess: (state, action: PayloadAction<ISite[]>) => {
      state.siteList = action.payload;
      state.error = null;
    },
    getSitesFailure: (state, action: PayloadAction<AxiosError>) => {
      state.siteList = [];
      state.error = action.payload;
    },

    getAvailableForOneNightRequest: (
      state,
      action: PayloadAction<IGetAvailableForOneNightPayload>,
    ) => {
      state.sitesAvailableForOneNight = [];
      state.error = null;
    },
    getAvailableForOneNightSuccess: (
      state,
      action: PayloadAction<IGetSitesAvailableForOneNightResponse>,
    ) => {
      state.sitesAvailableForOneNight = action.payload.siteNames;
      state.error = null;
    },
    getAvailableForOneNightFailure: (state, action) => {
      state.sitesAvailableForOneNight = [];
      state.error = action.payload;
    },
  },
});

export const {
  getSiteCntRequest,
  getSiteCntSuccess,
  getSiteCntFailure,
  getSitesRequest,
  getSitesSuccess,
  getSitesFailure,
  getAvailableForOneNightRequest,
  getAvailableForOneNightSuccess,
  getAvailableForOneNightFailure,
} = siteSlice.actions;

export default siteSlice.reducer;
