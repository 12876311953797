import {
  IBookingInfo,
  ISite,
  IBooking,
  IRegisterdAdditionalUserInfoResponse,
} from '@types';
import {
  ReservationState,
  ReservationDate,
  PeopleCnt,
  ICalculateBookingRequestPayload,
  IReservePayload,
  ICarInfo,
  IGetBookingInfoPayload,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ReservationState = {
  date: {} as ReservationDate,
  peopleCnt: {
    adultCnt: 2,
    teenCnt: 0,
    childCnt: 0,
  },
  site: {} as ISite,
  selectedServices: [],
  bookingInfo: {} as IBookingInfo,
  isBookingInfoFetched: false,
  bookingDetail: {} as IBooking,
  holidays: [],
  carInfo: {} as ICarInfo,
  petInfo: [],
  registerdAdditionalUserInfo: {
    RegisterdCarNumber: '',
    RegisterdPetInfo: [],
  },
  error: '',
  selfCheckinStatus: '',
  selfCheckinErrorMessage: '',
};

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    getReservationDate: (state, action: PayloadAction<ReservationDate>) => {
      state.date = action.payload;
    },
    increaseNumOfPeople: (state, action: PayloadAction<keyof PeopleCnt>) => {
      state.peopleCnt[action.payload] += 1;
    },
    decreaseNumOfPeople: (state, action: PayloadAction<keyof PeopleCnt>) => {
      state.peopleCnt[action.payload] -= 1;
    },
    selectSite: (state, action: PayloadAction<ISite>) => {
      state.site = action.payload;
    },

    getBookingInfoRequest: (
      state,
      action: PayloadAction<IGetBookingInfoPayload>,
    ) => {
      state.bookingInfo = {} as IBookingInfo;
      state.error = '';
    },
    getBookingInfoSuccess: (state, action: PayloadAction<IBookingInfo>) => {
      state.bookingInfo = action.payload;
      state.isBookingInfoFetched = true;
      state.error = '';
    },
    getBookingInfoFailure: (state, action: PayloadAction<Error | string>) => {
      state.bookingInfo = {} as IBookingInfo;
      state.error = action.payload;
    },

    resetBookingInfo: state => {
      state.bookingInfo = {} as IBookingInfo;
    },

    setIsBookingInfoFetched: (state, action: PayloadAction<boolean>) => {
      state.isBookingInfoFetched = action.payload;
    },

    completeSelection: state => {
      state;
    },

    completeSelectionInZone: (state, action: PayloadAction<string>) => {
      state;
    },

    setBasicPeopleCnt: state => {
      if (
        state.peopleCnt.adultCnt === 0 &&
        state.peopleCnt.childCnt === 0 &&
        state.peopleCnt.teenCnt === 0
      ) {
        state.peopleCnt.adultCnt = 2;
      }
    },

    reserve: (state, action: PayloadAction<IReservePayload>) => {
      const {
        numOfCars,
        services,
        hasTrailer,
        hasCampingCar,
        carNumbers,
        extraCarCnt,
        petInfo,
      } = action.payload;

      state.selectedServices = services;
      state.carInfo = {
        carCnt: numOfCars,
        extraCarCnt,
        carNumbers,
        hasTrailer,
        hasCampingCar,
      };
      state.petInfo = petInfo;
    },

    calculateBookingRequest: (
      state,
      action: PayloadAction<ICalculateBookingRequestPayload>,
    ) => {
      state.bookingDetail = {} as IBooking;
      state.error = '';
    },
    calculateBookingSuccess: (state, action: PayloadAction<IBooking>) => {
      state.bookingDetail = action.payload;
      state.error = '';
    },
    calculateBookingFailure: (state, action: PayloadAction<Error | string>) => {
      state.carInfo = {} as ICarInfo;
      state.selectedServices = [];
      state.bookingDetail = {} as IBooking;
      state.error = action.payload;
    },

    getRegisterdAdditionalUserInfoRequest: (state, action: PayloadAction) => {
      state.registerdAdditionalUserInfo = {
        RegisterdCarNumber: '',
        RegisterdPetInfo: [],
      };
    },
    getRegisterdAdditionalUserInfoSuccess: (
      state,
      action: PayloadAction<IRegisterdAdditionalUserInfoResponse>,
    ) => {
      state.registerdAdditionalUserInfo.RegisterdCarNumber =
        action.payload.carNumber;
      state.registerdAdditionalUserInfo.RegisterdPetInfo = action.payload.pets;
    },
    getRegisterdAdditionalUserInfoFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.registerdAdditionalUserInfo = {
        RegisterdCarNumber: '',
        RegisterdPetInfo: [],
      };
      state.error = action.payload;
    },
    submitSelfCheckinRequest: (state, action: PayloadAction<any>) => {
      state.selfCheckinStatus = 'loading';
      state.selfCheckinErrorMessage = '';
    },
    submitSelfCheckinSuccess: (state, action: PayloadAction<any>) => {
      state.selfCheckinStatus = 'success';
      state.selfCheckinErrorMessage = '';
    },
    submitSelfCheckinFailure: (state, action: PayloadAction<any>) => {
      state.selfCheckinStatus = 'error';
      state.selfCheckinErrorMessage = action.payload;
    },
    setSelfCheckinStatus: (state, action: PayloadAction<string>) => {
      state.selfCheckinStatus = action.payload;
    },
    setSelfCheckinErrorMessage: (state, action: PayloadAction<string>) => {
      state.selfCheckinErrorMessage = action.payload;
    },
  },
});

export const {
  getReservationDate,
  increaseNumOfPeople,
  decreaseNumOfPeople,
  selectSite,
  getBookingInfoRequest,
  getBookingInfoSuccess,
  getBookingInfoFailure,
  resetBookingInfo,
  setIsBookingInfoFetched,
  reserve,
  completeSelection,
  completeSelectionInZone,
  calculateBookingRequest,
  calculateBookingSuccess,
  calculateBookingFailure,
  setBasicPeopleCnt,
  getRegisterdAdditionalUserInfoRequest,
  getRegisterdAdditionalUserInfoSuccess,
  getRegisterdAdditionalUserInfoFailure,
  submitSelfCheckinRequest,
  submitSelfCheckinSuccess,
  submitSelfCheckinFailure,
  setSelfCheckinStatus,
  setSelfCheckinErrorMessage,
} = reservationSlice.actions;

export default reservationSlice.reducer;
