import {
  PaymentState,
  IBookRequestPayload,
  IStartBookingPayload,
  ICheckPaymentRequestPayload,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: PaymentState = {
  error: '',
  isOncePaymentSuccess: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    startBooking: (state, action: PayloadAction<IStartBookingPayload>) => {
      state;
    },

    bookRequest: (state, action: PayloadAction<IBookRequestPayload>) => {
      state.error = '';
      state.isOncePaymentSuccess = false;
    },
    bookSuccess: (state, action) => {
      state.error = '';
      if (!action.payload.needOnlinePayment) state.isOncePaymentSuccess = true;
    },
    bookFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    resetIsOncePaymentSuccess: state => {
      state.isOncePaymentSuccess = false;
    },

    checkPaymentRequest: (
      state,
      action: PayloadAction<ICheckPaymentRequestPayload>,
    ) => {
      state.error = '';
    },
    checkPaymentSuccess: state => {
      state.error = '';
    },
    checkPaymentFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  startBooking,
  bookRequest,
  bookSuccess,
  bookFailure,
  resetIsOncePaymentSuccess,
  checkPaymentRequest,
  checkPaymentSuccess,
  checkPaymentFailure,
} = paymentSlice.actions;

export default paymentSlice.reducer;
