import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BannerState } from 'store/types';
import { IBanner, ILandingPage, IProductMainBanners } from '@types';

const initialState: BannerState = {
  banners: [],
  pendingBanners: [],
  productBanners: {} as IProductMainBanners,
  communityBanners: [],
  introBanners: [],
  landing: {} as ILandingPage,
  error: '',
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    getBannersRequest: (state, action: PayloadAction) => {
      state.banners = [];
      state.error = '';
    },
    getBannersSuccess: (state, action: PayloadAction<IBanner[]>) => {
      state.banners = action.payload;
      state.error = '';
    },
    getBannersFailure: (state, action: PayloadAction<Error | string>) => {
      state.banners = [];
      state.error = action.payload;
    },
    getPendingBannersRequest: (state, action: PayloadAction) => {
      state.pendingBanners = [];
      state.error = '';
    },
    getPendingBannersSuccess: (state, action: PayloadAction<IBanner[]>) => {
      state.pendingBanners = action.payload;
      state.error = '';
    },
    getPendingBannersFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.pendingBanners = [];
      state.error = action.payload;
    },
    getLandingRequest: (state, action: PayloadAction<string>) => {
      state.landing = {} as ILandingPage;
      state.error = '';
    },
    getLandingSuccess: (state, action: PayloadAction<ILandingPage>) => {
      state.landing = action.payload;
      state.error = '';
    },
    getLandingFailure: (state, action: PayloadAction<Error | string>) => {
      state.landing = {} as ILandingPage;
      state.error = action.payload;
    },
    getProductBannersRequest: (state, action: PayloadAction) => {
      state.productBanners = {} as IProductMainBanners;
      state.error = '';
    },
    getProductBannersSuccess: (
      state,
      action: PayloadAction<IProductMainBanners>,
    ) => {
      state.productBanners = action.payload;
      state.error = '';
    },
    getProductBannersFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.productBanners = {} as IProductMainBanners;
      state.error = action.payload;
    },
    getCommunityBannersRequest: (state, action: PayloadAction) => {
      state.communityBanners = [];
      state.error = '';
    },
    getCommunityBannersSuccess: (state, action: PayloadAction<IBanner[]>) => {
      state.communityBanners = action.payload;
      state.error = '';
    },
    getCommunityBannersFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.communityBanners = [];
      state.error = action.payload;
    },
    getIntroBannersRequest: state => {},
    getIntroBannersSuccess: (state, action: PayloadAction<IBanner[]>) => {
      state.introBanners = action.payload;
    },
    getIntroBannersFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getBannersRequest,
  getBannersSuccess,
  getBannersFailure,
  getPendingBannersRequest,
  getPendingBannersSuccess,
  getPendingBannersFailure,
  getLandingRequest,
  getLandingSuccess,
  getLandingFailure,
  getProductBannersRequest,
  getProductBannersSuccess,
  getProductBannersFailure,
  getCommunityBannersRequest,
  getCommunityBannersSuccess,
  getCommunityBannersFailure,
  getIntroBannersRequest,
  getIntroBannersSuccess,
  getIntroBannersFailure,
} = bannerSlice.actions;

export default bannerSlice.reducer;
