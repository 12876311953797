import { takeEvery, takeLatest, all, select, put } from 'redux-saga/effects';
import {
  cancelBookingFailure,
  cancelBookingRequest,
  cancelBookingSuccess,
  getCancelChargeFailure,
  getCancelChargeRequest,
  getCancelChargeSuccess,
  updateRefundInfoFailure,
  updateRefundInfoRequest,
  updateRefundInfoSuccess,
  setRefundState,
} from 'store/reducers/cancel';
import { getCancelCharge, cancelBooking, updateRefundInfo } from 'api';
import { RootState } from 'store/reducers';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';
import { customHistory } from '../../App';
import { IResponse } from '../../@types/index';

const queryString = require('query-string');

function* updateRefundInfoSaga() {
  yield takeLatest(
    updateRefundInfoRequest.type,
    createFetchAction(
      updateRefundInfo,
      updateRefundInfoSuccess,
      updateRefundInfoFailure,
      function* success() {
        const token = queryString.parse(customHistory.location.search).token;
        const query = token ? `?token=${token}` : '';

        const id: string = yield select(
          (state: RootState) => state.reservationReducer.bookingInfo.id,
        );

        yield customHistory.replace(`/reservation/result/${id}${query}`);
      },
      failure,
    ),
  );
}

function* cancelBookingSaga() {
  yield takeLatest(
    cancelBookingRequest.type,
    createFetchAction(
      cancelBooking,
      cancelBookingSuccess,
      cancelBookingFailure,
      function* success() {
        if (window.location.pathname.includes('result')) {
          // yield window.location.reload();
          yield put(setRefundState('success'));
        } else {
          const token = queryString.parse(customHistory.location.search).token;
          const query = token ? `?token=${token}` : '';

          const id: string = yield select(
            (state: RootState) => state.reservationReducer.bookingInfo.id,
          );

          yield customHistory.replace(`/reservation/result/${id}${query}`);
          yield put(setRefundState('success'));
        }
      },
      failure,
    ),
  );
}

function* getCancelChargeSaga() {
  yield takeEvery(
    getCancelChargeRequest.type,
    createFetchAction(
      getCancelCharge,
      getCancelChargeSuccess,
      getCancelChargeFailure,
      undefined,
      function* fail(error: Error | IResponse) {
        yield failure(error);
        customHistory.goBack();
      },
    ),
  );
}

export default function* cancelSaga() {
  yield all([
    getCancelChargeSaga(),
    cancelBookingSaga(),
    updateRefundInfoSaga(),
  ]);
}
