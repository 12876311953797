// import { toast } from 'react-toastify';
export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const share = (name: string | undefined, url: string) => () => {
  if (isMobile()) {
    const shareDialog = document.querySelector('.share-dialog');
    if (navigator.share) {
      navigator
        .share({
          title: `[캠핏] ${name}`,
          url,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      shareDialog?.classList.add('is-open');
    }
  } else {
    alert('링크가 복사되었습니다.');
  }
};
