import usePageViews from 'hooks/usePageViews';
import PrivateRoute from 'PrivateRoute';
import { Route, Switch } from 'react-router-dom';
import { retryLazy } from 'utils/lazyUtil';

const AccountFind = retryLazy(() => import('pages/AccountFind'));
/* 
배송지-관련
const AddressList = retryLazy(() => import('pages/AddressList'));
const AddressEdit = retryLazy(() => import('pages/AddressEdit'));
*/
const AppSetting = retryLazy(() => import('pages/AppSetting'));
const ArticleDetail = retryLazy(() => import('pages/ArticleDetail'));
const Board = retryLazy(() => import('pages/Board'));
const BoardDetail = retryLazy(() => import('pages/BoardDetail'));
const BoardEdit = retryLazy(() => import('pages/BoardEdit'));
const BookingOpenSchedules = retryLazy(
  () => import('pages/BookingOpenSchedules'),
);
const CampAnnounceDetail = retryLazy(() => import('pages/CampAnnounceDetail'));
const CampAnnounceList = retryLazy(() => import('pages/CampAnnounceList'));
const CampDetail = retryLazy(() => import('pages/CampDetail'));
const CamperInfo = retryLazy(() => import('pages/CamperInfo'));
const CampLogList = retryLazy(() => import('pages/CampLogList'));
const CampingLogDetail = retryLazy(() => import('pages/CampingLogDetail'));
const Comment = retryLazy(() => import('pages/CommentDetail'));
const CommentList = retryLazy(() => import('pages/Comments'));
const CompleteRegisteringRealUser = retryLazy(
  () => import('pages/CompleteRegisteringRealUser'),
);
const CouponDetail = retryLazy(() => import('pages/CouponDetail'));
const Exhibition = retryLazy(() => import('pages/Exhibition'));
const IdFind = retryLazy(() => import('pages/IdFind'));
const ItemsTimeline = retryLazy(() => import('pages/ItemsTimeline'));
const Landing = retryLazy(() => import('pages/Landing'));
const LogEdit = retryLazy(() => import('pages/LogEdit'));
const Login = retryLazy(() => import('pages/LogIn'));
const Main = retryLazy(() => import('pages/Main'));
const MyAlarms = retryLazy(() => import('pages/MyAlarms'));
const MyBlockedHistory = retryLazy(() => import('pages/MyBlockedHistory'));
const MyBookmarks = retryLazy(() => import('pages/MyBookmarks'));
const MyCampingLog = retryLazy(() => import('pages/MyCampingLog'));
const MyCoupons = retryLazy(() => import('pages/MyCoupons'));
const MyPageCouponRegister = retryLazy(
  () => import('pages/MyPageCouponRegister'),
);
const MyInfoEdit = retryLazy(() => import('pages/MyInfoEditPage'));
const MyItemEdit = retryLazy(() => import('pages/MyItemEdit'));
const MyList = retryLazy(() => import('pages/MyList'));
const MyPage = retryLazy(() => import('pages/MyPage'));
const MyPageEvent = retryLazy(() => import('pages/MyPageEvent'));
const Shopping = retryLazy(() => import('pages/Shopping'));
const MyPageExhibition = retryLazy(() => import('pages/MyPageExhibition'));
const MyReservationList = retryLazy(() => import('pages/MyReservationList'));
const MyVacancyAlarms = retryLazy(() => import('pages/MyVacancyAlarms'));
const NicknameSetting = retryLazy(() => import('pages/UserNicknameSetting'));
const NonReservationList = retryLazy(() => import('pages/NonReservationList'));
const NonReservationResult = retryLazy(
  () => import('pages/NonReservationResult'),
);
const Notice = retryLazy(() => import('pages/Notice'));
const NoticeDetail = retryLazy(() => import('pages/NoticeDetail'));
const Pamphlet = retryLazy(() => import('pages/Pamphlet'));
const PasswordFind = retryLazy(() => import('pages/PasswordFind'));
const PasswordSetting = retryLazy(() => import('pages/UserPasswordSetting'));
const Payment = retryLazy(() => import('pages/Payment'));
const PaymentLoading = retryLazy(() => import('pages/PaymentLoading'));
const ProductDetail = retryLazy(() => import('pages/NewProductDetail'));
const ProductExhibition = retryLazy(() => import('pages/ProductExhibition'));
const ProductMain = retryLazy(() => import('pages/ProductMain'));
const ProductMainCategory = retryLazy(
  () => import('pages/ProductMainCategory'),
);
const ProductQnaList = retryLazy(() => import('pages/ProductQnaList'));
const ProductQuestionEdit = retryLazy(
  () => import('pages/ProductQuestionEdit'),
);
const ProductReviewDetail = retryLazy(
  () => import('pages/ProductReviewDetail'),
);
const ProductReviewList = retryLazy(() => import('pages/ProductReviewList'));
const ProductSearchResults = retryLazy(
  () => import('pages/ProductSearchResults'),
);
const RelatedProductList = retryLazy(() => import('pages/RelatedProductList'));
const RealtimeReservation = retryLazy(
  () => import('pages/RealtimeReservation'),
);
const Reservation = retryLazy(() => import('pages/Reservation'));
const ReservationAccountInfo = retryLazy(
  () => import('pages/ReservationAccountInfo'),
);
const ReservationRefund = retryLazy(() => import('pages/ReservationRefund'));
const ReservationResult = retryLazy(() => import('pages/ReservationResult'));
const ReservationServiceInventory = retryLazy(
  () => import('pages/ReservationServiceInventory'),
);
const SearchList = retryLazy(() => import('pages/SearchResults'));
const SearchVacancy = retryLazy(() => import('pages/SearchVacancy'));
const SearchGeo = retryLazy(() => import('pages/SearchGeo'));
const Settings = retryLazy(() => import('pages/Settings'));
const SignUp = retryLazy(() => import('pages/SignUp'));
const SignUpComplete = retryLazy(() => import('pages/SignUpComplete'));
const SubBannerDetail = retryLazy(() => import('pages/SubBannerDetail'));
const TermsDetail = retryLazy(() => import('pages/TermsDetail'));
const TooManyReqError = retryLazy(() => import('pages/TooManyReqError'));
const UserBriefSetting = retryLazy(() => import('pages/UserBriefSetting'));
const UserSnsSetting = retryLazy(() => import('pages/UserSnsSetting'));
const UserCarNumberSetting = retryLazy(
  () => import('pages/UserCarNumberSetting'),
);
const UserPetsSetting = retryLazy(() => import('pages/UserPetsSetting'));
const UserPetsEdit = retryLazy(() => import('pages/UserPetsEdit'));
const Withdrawal = retryLazy(() => import('pages/Withdrawal'));
const ZoneDetail = retryLazy(() => import('pages/ZoneDetail'));
const SiteReservationStatus = retryLazy(
  () => import('pages/ZoneReservationStatus'),
);
const HostsAndSuppliersList = retryLazy(
  () => import('pages/HostsAndSuppliersList'),
);

const BasicButtonTest = retryLazy(
  () => import('pages/componentTest/BasicButtons'),
);
const NewBasicButtonTest = retryLazy(
  () => import('pages/componentTest/NewBasicButtons'),
);
const IconComponentTest = retryLazy(
  () => import('pages/componentTest/IconComponents'),
);
const ModalTest = retryLazy(() => import('pages/componentTest/Modals'));
const Typographys = retryLazy(() => import('pages/componentTest/Typographys'));
const Toast = retryLazy(() => import('pages/componentTest/Toast'));
const AutenticationTest = retryLazy(
  () => import('pages/componentTest/SelfAuthentication'),
);
const CouponTest = retryLazy(() => import('pages/componentTest/Coupons'));

const DormantAccount = retryLazy(() => import('pages/DormantAccount'));

const SwitchRoute = () => {
  usePageViews();
  return (
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/sign-up/complete" component={SignUpComplete} />
      <Route exact path="/find" component={AccountFind} />
      <Route exact path="/find/id" component={IdFind} />
      <Route exact path="/find/pw" component={PasswordFind} />
      <Route exact path="/camp/:campId" component={CampDetail} />
      <Route
        exact
        path="/camp/:campId/announces"
        component={CampAnnounceList}
      />
      <Route
        exact
        path="/camp/:campId/announces/:announceId"
        component={CampAnnounceDetail}
      />

      <Route exact path="/camp/:campId/log" component={CampLogList} />
      <Route exact path="/camp/:campId/:zoneId/log" component={CampLogList} />
      <Route exact path="/camp/:campId/:zoneId" component={ZoneDetail} />
      <Route
        exact
        path="/zones/:zoneId/calendar"
        component={SiteReservationStatus}
      />
      <Route exact path="/camp/:campId/log/:id" component={CampingLogDetail} />
      <Route
        exact
        path="/camp/:campId/:zoneId/log/:id"
        component={CampingLogDetail}
      />
      <Route exact path="/search" component={RealtimeReservation} />
      <Route exact path="/search/result" component={SearchList} />
      <Route exact path="/search/vacancy" component={SearchVacancy} />
      <Route exact path="/search/geo" component={SearchGeo} />
      <Route exact path="/reservation" component={Reservation} />
      <Route exact path="/reservation/payment" component={Payment} />
      <Route
        exact
        path="/reservation/result/:id"
        component={ReservationResult}
      />
      <Route
        exact
        path="/reservation/inventory"
        component={ReservationServiceInventory}
      />
      <Route exact path="/reservation/refund" component={ReservationRefund} />
      <Route
        exact
        path="/reservation/refund/account-info"
        component={ReservationAccountInfo}
      />
      <Route exact path="/log/:id" component={CampingLogDetail} />
      <Route
        exact
        path="/reservation/payment/loading"
        component={PaymentLoading}
      />
      <Route exact path="/mypage" component={MyPage} />
      <Route
        exact
        path="/mypage/non/reservation"
        component={NonReservationList}
      />
      <Route
        exact
        path="/mypage/non/reservation/list"
        component={NonReservationResult}
      />
      <PrivateRoute
        exact
        path="/mypage/reservation"
        component={MyReservationList}
      />
      <PrivateRoute
        exact
        path="/mypage/reservation/edit"
        component={MyCampingLog}
      />
      <PrivateRoute exact path="/mypage/mylist" component={MyList} />
      <PrivateRoute exact path="/mypage/log/edit" component={LogEdit} />
      <PrivateRoute exact path="/mypage/log/edit/:id" component={LogEdit} />
      <Route exact path="/mypage/notice" component={Notice} />
      <Route exact path="/mypage/notice/:id" component={NoticeDetail} />
      <PrivateRoute exact path="/mypage/coupon" component={MyCoupons} />
      <PrivateRoute
        exact
        path="/mypage/coupon/register"
        component={MyPageCouponRegister}
      />
      <PrivateRoute exact path="/mypage/coupon/:id" component={CouponDetail} />
      <PrivateRoute exact path="/mypage/bookmark" component={MyBookmarks} />
      <Route exact path="/mypage/event" component={MyPageEvent} />
      <Route exact path="/shopping" component={Shopping} />
      <Route exact path="/mypage/exhibition" component={MyPageExhibition} />
      <Route exact path="/exhibition/:id" component={Exhibition} />
      <Route exact path="/pamphlet" component={Pamphlet} />
      <Route exact path="/contents" component={SubBannerDetail} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/error" component={TooManyReqError} />
      <PrivateRoute exact path="/mypage/info/edit" component={MyInfoEdit} />
      <PrivateRoute
        exact
        path="/settings/password"
        component={PasswordSetting}
      />
      <PrivateRoute
        exact
        path="/settings/nickname"
        component={NicknameSetting}
      />
      <PrivateRoute exact path="/settings/brief" component={UserBriefSetting} />
      <PrivateRoute exact path="/settings/sns" component={UserSnsSetting} />
      <PrivateRoute
        exact
        path="/settings/carnumber"
        component={UserCarNumberSetting}
      />
      <PrivateRoute exact path="/settings/pets" component={UserPetsSetting} />
      <PrivateRoute exact path="/settings/pets/edit" component={UserPetsEdit} />
      <PrivateRoute exact path="/withdrawal" component={Withdrawal} />
      <PrivateRoute exact path="/board/edit" component={BoardEdit} />
      <PrivateRoute exact path="/board/edit/:id" component={BoardEdit} />
      <Route exact path="/board" component={Board} />
      <Route exact path="/board/:boardId" component={BoardDetail} />
      <Route
        exact
        path="/board/:boardId/comments/:commentId"
        component={Comment}
      />
      <Route exact path="/board/:boardId/comments" component={CommentList} />
      <Route exact path="/terms/:id" component={TermsDetail} />
      <Route exact path="/landing/:id" component={Landing} />
      <Route exact path="/mypage/alarms" component={MyAlarms} />
      <Route exact path="/users/:nickname" component={CamperInfo} />
      <Route exact path="/schedules" component={BookingOpenSchedules} />
      <Route exact path="/mypage/vacancy-alarms" component={MyVacancyAlarms} />
      <PrivateRoute exact path="/settings/block" component={MyBlockedHistory} />
      <Route exact path="/board/articles/:id" component={ArticleDetail} />
      <Route exact path="/app" component={AppSetting} />
      <Route exact path="/mypage/item/edit" component={MyItemEdit} />
      <Route
        exact
        path="/complete/registering/real-user"
        component={CompleteRegisteringRealUser}
      />
      <Route exact path="/items/:nickname" component={ItemsTimeline} />
      {/* 
      배송지-관련
      <Route exact path="/addressList" component={AddressList} />
      <Route exact path="/addressEdit" component={AddressEdit} />
       */}
      <Route exact path="/products" component={ProductSearchResults} />
      <Route exact path="/products/:id" component={ProductDetail} />
      <Route
        exact
        path="/products/:id/question/edit"
        component={ProductQuestionEdit}
      />

      {/* 임의로 새로운 path 추가함
      <Route
        exact
        path="/products/question/edit"
        component={ProductQuestionEdit}
      /> */}

      <Route exact path="/product" component={ProductMain} />
      <Route exact path="/product/questions" component={ProductQnaList} />
      <Route exact path="/product/reviews" component={ProductReviewList} />
      <Route
        exact
        path="/product/related/:productId"
        component={RelatedProductList}
      />
      <Route
        exact
        path="/product/:categoryId"
        component={ProductMainCategory}
      />
      <Route
        exact
        path="/product-exhibition/:id"
        component={ProductExhibition}
      />
      <Route
        exact
        path="/product/reviews/:id"
        component={ProductReviewDetail}
      />
      <Route exact path="/hosts" component={HostsAndSuppliersList} />
      <Route exact path="/suppliers" component={HostsAndSuppliersList} />
      <Route exact path="/dormant" component={DormantAccount} />

      <Route exact path="/test/BasicButtons" component={BasicButtonTest} />
      <Route
        exact
        path="/test/NewBasicButtons"
        component={NewBasicButtonTest}
      />
      <Route exact path="/test/IconComponents" component={IconComponentTest} />
      <Route exact path="/test/Modals" component={ModalTest} />
      <Route exact path="/test/Typographys" component={Typographys} />
      <Route exact path="/test/Toast" component={Toast} />
      <Route
        exact
        path="/test/AutenticationTest"
        component={AutenticationTest}
      />
      <Route exact path="/test/CouponTest" component={CouponTest} />

      <Route component={Main} />
    </Switch>
  );
};

export default SwitchRoute;
